import React from 'react'
import { Box } from 'theme-ui'

const ScreenReaderText = () => <Box 
  as='span'
  sx={{
    position: 'absolute',
    left: '-999em'
  }}
/>

const Arrow = ({onClick, direction, children}) => {
  let x
  if (direction === 'prev') {
    x = `-4rem`
    // left: 0
  } else {
    x = `4rem`
    // right: 0
  }  
  return (
    <Box
      className='arrow'
      onClick={onClick}
      sx={{
        display: ['none', null, 'flex'],
        flexDirection: 'column',
        justifyContent: 'center',
        zIndex: 111,
        position: 'absolute',
        width: ['8.3333333333%'],
        top: 0,
        bottom: 0,
        ...(direction === 'prev' && { left: 0, pl: 5, alignItems: 'start', transform: 'translateX(-10pt)' }),
        ...(direction === 'next' && { right: 0, pr: 5, alignItems: 'end', transform: 'translateX(10pt)' }),
        outline: 'none',
        cursor: 'pointer',
        transition: `300ms transform ease-in-out, 300ms opacity ease-in-out`,
        opacity: 0,
        // opacity: isVisible ? 1 : 0,
        // transform: isVisible ? `translateX(0)` : `translateX(${x})`,
        [`&:hover`]: {
          textDecoration: 'none'
        },
        'span': {
          width: '40px',
          height: '40px',
          lineHeight: '46px',
          display: 'inline-block',
          textAlign: 'center',
          bg: 'white',
          borderRadius: '50%',
          fontSize: 3
        }
    }}
    >{children}</Box>
  )
}

const Arrows = ({handlePrevClick, handleNextClick }) => {
  return (
    <React.Fragment>
      <Arrow
        onClick={handlePrevClick}
        role={'link'}
        tabIndex={'0'}
        direction={'prev'}
      ><span>{`←`}</span><ScreenReaderText>{ 'Prev' }</ScreenReaderText></Arrow>
      <Arrow
        onClick={handleNextClick}
        role={'link'}
        tabIndex={'0'}
        direction={'next'}
      ><span>{`→`}</span><ScreenReaderText>{ 'Next' }</ScreenReaderText></Arrow>      
    </React.Fragment>
  )
}

export default Arrows
