import React, { useReducer, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

import { carouselReducer } from './carouselReducer';
import { swipingHandler, swipeHandler } from './carouselSwipingActions';

const initialCarouselState = {
  offset: 0,
  desired: 0,
  active: 0
};

// slidesPresented is not passed into the hook currently
// but I am not sure I want that type of logic anyway
// although I think its meant in part to control how many clones you have
// with beforeIndices and afterIndices
// but our usecase is more like, show as many items as fit on the screen


export const useCarousel = (length, { widths, widthsWithClones, totalWidth, totalWidthWithClones }) => {
  const [state, dispatch] = useReducer(carouselReducer, initialCarouselState);
  const transitionTime = 400;

  const handlers = useSwipeable({
    onSwiping: (e) => swipingHandler(e, dispatch),
    onSwipedLeft: (e) => swipeHandler(e, dispatch, length, 1),
    onSwipedRight: (e) => swipeHandler(e, dispatch, length, -1),
    trackMouse: false,
    trackTouch: true
  });

  const next = () => {
    dispatch({ type: 'next', length });
  }

  const prev = () => {
    dispatch({ type: 'prev', length });
  }
  // how to add click handlers for prev/next buttons
  // return a clickHandlers object from this hook

  // onTransitionEnd instead of this? because our distance to travel differ
  useEffect(() => {
    const id = setTimeout(() => dispatch({ type: "done" }), transitionTime);
    return () => clearTimeout(id);
  }, [state.desired]);

  const cloneCount = (widthsWithClones.length - widths.length) / 2
  // account for X clones (not sure how that makes it work yet)
  const left = widthsWithClones.slice(0, state.active + cloneCount)
    .reduce((acc, width) => acc + width, 0)

  const style = {
    transform: 'translateX(0)',
    // here the width needs to be calculated based on the rendered slides
    // or the known width of each image given the target height of the carousel
    // width: `${100 * (length + 2)}%`,
    width: `${totalWidthWithClones}px`,
    // width: `${totalWidth}px`,
    // use translateX here instead of left... wait, left is just to set the position, translateX is used for animation
    // left: `-${(state.active + 1) * 100}%`,
    left: `-${left}px`,
    // state.active + 1 (is the target index I think)
    // we could try adding the widths up till that index?

  };

  const elastic = `transform ${transitionTime}ms cubic-bezier(0.68, -0.55, 0.265, 1.55)`;
  const smooth = `transform ${transitionTime}ms ease`;

  if (state.desired !== state.active) {
    // console.log('state.active - state.desired: ', state.active, state.desired)
    const distanceByIndex = Math.abs(state.active - state.desired);
    const pref = Math.sign(state.offset || 0);
    const dir = (distanceByIndex > length / 2 ? 1 : -1) * Math.sign(state.desired - state.active);

    let distanceInWidth
    if (dir === -1) {
      // moving "next"
      distanceInWidth = widths[state.active]
    } else if (state.active === 0 && dir === 1) {
      // moving "prev" but at the first slide
      distanceInWidth = widths[length - 1]
    } else {
      // moving "prev"
      distanceInWidth = widths[state.active - 1]
    }

    const shift = (pref || dir) * distanceInWidth

    // console.log('desired !== active: ', { distanceByIndex, pref, dir, shift })
    style.transition = smooth;
    style.transform = `translateX(${shift}px)`;
  } else if (!isNaN(state.offset)) { // this is only for swiping/dragging
    if (state.offset !== 0) {
      style.transform = `translateX(${state.offset}px)`;
    } else {
      style.transition = elastic;
    }
  }

  return {
    active: state.active,
    setActive: n => dispatch({ type: 'jump', desired: n }),
    handlers,
    next,
    prev,
    style
  };

};

export function makeIndices(start, delta, num) {
  const indices = [];

  while (indices.length < num) {
    indices.push(start);
    start += delta;
  }

  return indices;
}
