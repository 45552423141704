import React from 'react'
import { Container, Grid, Text, Box } from 'theme-ui'
import BaseBlockContent from '@sanity/block-content-to-react'
import PageBlockContent from 'components/PageBlockContent'
import Related from 'components/Related'
import serializers from 'components/Modules/serializers'

function Page(props) {
  const {
    inverted,
    title,
    _rawModules,
    _rawBody,
    relatedInitiatives = []
  } = props

  const blocks = (_rawModules || []).map((block, position) => {
    return {
      ...block,
      alignSelf: position < _rawModules.length / 2 ? 'end' : 'start'
    }
  })

  return (
    <Container as='article' pt={props.pt || 9} px={props.px || 2} pb={2} bg={inverted ? 'black' : 'white'}>
      {_rawModules && <PageBlockContent blocks={blocks} inverted={inverted} />}
      {_rawBody &&
        <BaseBlockContent renderContainerOnSingleChild={true} className='css-col-grid grid-end small-p' blocks={_rawBody} serializers={serializers(_rawBody, [])} />
      }
      {relatedInitiatives.length > 0 &&
        <Box sx={{mt: 8}}><Related nodes={relatedInitiatives} /></Box>}
    </Container>
  );
}

export default Page
