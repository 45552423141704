import React from 'react'
import { Grid, Text, Link, Box } from 'theme-ui'
import Corner from 'components/Corner'

const Categories = ({ isVisible, items, refine }) => {
  return <Box as='ul' sx={{
    transition: 'opacity .25s linear',
    opacity: isVisible ? 1 : 0,
    pointerEvents: isVisible ? 'auto' : 'none'
  }}>
    {(items || []).map(item =>
      <Box as='li' key={item.label} sx={{ position: 'relative' }}>
        <Link
          variant='inverted'
          onClick={() => refine(item.value)}
        >
          {item.label}
          <Text variant='frs' pl='1' sx={{display: 'inline-block'}}>{`(${item.count})`}</Text>
        </Link>
        <Box
          onClick={() => refine(item.value)}
          sx={{
            transition: 'opacity .05s linear, transform .25s ease-in-out',
            pointerEvents: item.isRefined ? 'auto' : 'none',
            opacity: item.isRefined ? 1 : 0,
            transform: item.isRefined ? 'translateX(100%)' : 'translateX(0)',
            variant: 'links.inverted',
            bg: 'black',
            lineHeight: '22px',
            width: '23px',
            textAlign: 'center',
            position: `absolute`,
            top: 0,
            right: '3px',
            borderTopRightRadius: 'default',
            borderBottomRightRadius: 'default'
          }}
        >×
        <Box sx={{ position: 'absolute', left: 0, top: '100%', pointerEvents: 'none'}}><Corner inverse top left/></Box>
        </Box>
      </Box>
    )}
  </Box>
}

export default Categories
