/** @jsx jsx */
import { jsx, Text, Link } from 'theme-ui'
import React from 'react'
import getResponsiveStyles from 'lib/getResponsiveStyles'

// ↧
const DownloadItem = ({ asset, title, inverted }) => {
  return (
    <li><Link variant={inverted ? 'inverted' : ''} sx={{ ['::before']: { content: `'↧'`, mr: 1 } }} href={asset.url} target='_blank'>{title || asset.originalFilename}</Link></li>
  )
}

// ↗
const ExternalLinkItem = ({ href, title, inverted }) => {
  return (
    <li><Link sx={{ ['::before']: { content: `'↗'`, mr: 1 } }} variant={inverted ? 'inverted' : ''} href={href} target='_blank'>{title}</Link></li>
  )
}

// →
const DefaultItem = ({ text }) => {
  return (
    <li sx={{ ['::before']: { content: `'→'`, mr: 1 } }}>{text}</li>
  )
}

const ListModule = ({ title, node, inverted }) => {
  // console.log('ListModule: ', node)
  return (
    <Text as='ul'
      sx={{
        mb: 5,
        ...(getResponsiveStyles('variant', ['text.frm', null, null, null, null, 'text.frl']))
      }}
    >
      { title && <li><Text
        sx={{
          ...(getResponsiveStyles('variant', ['text.frm', null, null, null, null, 'text.frl'])),
          color: inverted ? 'invertedText' : 'text'
        }}>{title}</Text></li>}
      {
        (node.items || []).map(({ _key, shortText, longText, title, href, asset, _type }) => {
          // use _type to detect which to render and pass needed props
          if (_type === 'download') {
            return (
              <DownloadItem key={_key} asset={asset} title={title} />
            )
          }
          if (_type === 'externalLink') {
            return (
              <ExternalLinkItem key={_key} href={href} title={title} inverted={inverted} />
            )
          }
          if (_type === 'listItem') {
            return (
              <DefaultItem key={_key} text={shortText || longText} />
            )
          }
        })
      }
     </Text>
  )
}

export default ListModule
