import React, { useEffect, useRef } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { Flex, Box, Input, Button } from 'theme-ui'

export default connectSearchBox(
  ({ setMenuState, hasFocus, refine, currentRefinement }) => {
    const searchInput = useRef(null)
    const handleReset = () => {
      refine('')
      setMenuState('')
    }
    // setting the focus on the searchInput causes the hits to flash/rerender
    // because an '' empty query is added and navigate is triggered
    // even with searchAsYouType={false}
    // using setTimeout lets the animation finish before the state is changed
    // but we still get the hits grid jumping around
    useEffect(() => {
      setTimeout(() => {
        if (hasFocus) {
          searchInput.current && searchInput.current.focus()
        } else {
          refine('')
          searchInput.current && searchInput.current.blur()
        }
      }, 1000)
    }, [hasFocus])
    return (
      <Flex as='form' noValidate action='' role='search' sx={{ alignItems: 'stretch', pointerEvents: hasFocus ? 'auto' : 'none' }}>
        <Input
          ref={searchInput}
          variant='inverted'
          type='text'
          placeholder=''
          aria-label='Search'
          onChange={e => refine(e.target.value)}
          value={currentRefinement}
          // disabled={!hasFocus}
        />
        {
          // hasFocus && <Box onClick={handleReset} sx={{variant: 'links.inverted', px: 2 }}>×</Box>
        }
      </Flex>
    )
})