/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import BaseBlockContent from '@sanity/block-content-to-react'
import { Heading, Text, Box } from 'theme-ui'
import Corner from 'components/Corner'
import SimpleFootnotes from 'components/Modules/SimpleFootnotes'
import { sizeToCol } from 'lib/helpers'
import serializers from './serializers'

function sizeToWidth(size) {
  switch (true) {
    case size === 'large':
      return { 'width': '83%' }
    case size === 'medium':
      return { 'width': '75%' }
    default:
      return { 'width': '75%' }
  }
}

const Caption = ({size, title}) =>
  <figcaption sx={{
    transition: 'opacity .5s, transform .25s',
    transform: 'translate(0, 0)',
    opacity: 1,
    position: 'absolute',
    zIndex: '200',
    bottom: '0',
    right: 0,
    ...(sizeToWidth(size)),
    p: '3pt 5pt 0',
    borderTopLeftRadius: 'default',
    borderBottom: 'none',
    bg: 'white'
  }}>
    <Box sx={{position: 'absolute', bottom: `100%`, right: `0`}}><Corner/></Box>
    <Box sx={{position: 'absolute', bottom: `0`, right: `100%`}}><Corner /></Box>
    {`${title}`}
  </figcaption>

const InvertedCaption = ({size, title}) =>
  <figcaption sx={{
    transition: 'opacity .5s, transform .25s',
    transform: 'translate(0, 0)',
    opacity: 1,
    position: 'absolute',
    zIndex: '200',
    top: 'calc(100% + 1px)',
    left: '-1px',
    right: 0,
    height: '10px',
    m: 0,
    bg: 'black',
    color: 'invertedText',
    display: 'flex',
    flexDirection: 'row',
    borderBottomRightRadius: 0,
    width: '100%'
  }}>
    <Box sx={{
      // bottom right corner element
      borderBottomLeftRadius: 'default',
      border: 'solid 1px',
      borderColor: 'invertedText',
      borderTop: 'none',
      borderRight: 'none',
      top: '-5px',
      bg: 'black',
      position: 'absolute',
      height: '22px',
      borderBottomRightRadius: 0,
      borderColor: 'invertedText',
      width: '80px',
      position: 'absolute'
    }}
    />
    <Box sx={{
      // bottom left corner element
      height: '7px',
      left: '80px',
      position: 'absolute',
      top: '10px',
      width: '5px',
      border: 'solid 1px',
      borderColor: 'invertedText',
      borderLeft: 'none',
      borderTop: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 'default',
    }}></Box>
    <Box sx={{
      // top right corner blocker
      top: '-5px',
      left: '60px',
      bg: 'black',
      position: 'absolute',
      height: '10px',
      width: '50px'
    }}
    />
    <Box sx={{
      // text and top right corner element
      left: '84px',
      height: '12px',
      top: '-1px',
      position: 'absolute',
      borderTopLeftRadius: 'default',
      border: 'solid 1px',
      borderColor: 'invertedText',
      borderRight: 'none',
      borderBottom: 'none',
      p: '3pt 5pt 0'
    }}>{`${title}`}</Box>
  </figcaption>

const PageRichTextModule = ({ node: { blocktext = [], title, promoted, size, alignSelf }, inverted }) => {
    const notes = blocktext && blocktext
      // filter out everything that's not a text block
      .filter(({ _type }) => _type === 'block')
      // make an array of the mark definitions of those blocks
      .reduce((acc, curr) => {
        return [...acc, ...curr.markDefs];
      }, [])
      // find all the footnote mark definitions
      .filter(({ _type }) => _type === 'footnote') || []

  return (
    <Box
      className='pageRichTextModule'
      sx={{
      position: 'relative',
      ...sizeToCol(size),
      alignSelf,
      bg: inverted ? 'black' : 'secondary',
      color: inverted ? 'invertedText' : 'black',
      border: inverted ? 'solid 1px' : 'none',
      borderColor: 'invertedText',
      borderRadius: 'default',
      pt: 2,
      px: 2,
      mt: inverted && '1px',
      mb: inverted && '17px',
      pb: !inverted && 5,
      'p': {
        mb: 1
      }
    }}>
      <BaseBlockContent blocks={blocktext} serializers={serializers(blocktext, notes, 0, inverted)} />
      <SimpleFootnotes blocks={blocktext} />
      { inverted ? <InvertedCaption size={promoted && 'large'} title={title}  /> : <Caption size={promoted && 'large'} title={title}  /> }
    </Box>
  )
}

export default PageRichTextModule
