import React from 'react'
import { useMediaQuery } from 'react-responsive'

const defaultConfig = [
  '0px',
  '320px',
  '768px',
  '1280px',
  '1680px',
  '2560px'
]

export default (responsiveConfig) => {
  const isComplex = typeof responsiveConfig[0] === 'object'
  const viewport = [
    useMediaQuery({ query: '(min-width: 0px)' }),
    useMediaQuery({ query: '(min-width: 320px)' }),
    useMediaQuery({ query: '(min-width: 768px)' }),
    useMediaQuery({ query: '(min-width: 1280px)' }),
    useMediaQuery({ query: '(min-width: 1680px)' }),
    useMediaQuery({ query: '(min-width: 2560px)' }),
  ]
  let lastConfig = null
  const responsiveConfigArray = viewport.map((isTrue, viewportIndex) => {
    const inputConfig = responsiveConfig[viewportIndex]
    if (!inputConfig) { // checks for null and if index exisits
      if (!lastConfig) {
        console.error('No responsive config found', defaultConfig[viewportIndex])
      }
      return lastConfig // returns null (1st iteration) or previous config
    }
    if (!isComplex) { // guess it's a string
      lastConfig = inputConfig
      return inputConfig
    }
    const mergedConfigs = { ...lastConfig, ...inputConfig }
    lastConfig = inputConfig
    return mergedConfigs
  })
  if (viewport.lastIndexOf(true) === -1) { // see if this works
    return responsiveConfigArray[3]
  }
  return responsiveConfigArray[viewport.lastIndexOf(true)]
}
