import React from 'react'
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group'
import { Box } from 'theme-ui'

const EASE = `cubic-bezier(0.59, 0.01, 0.28, 1)`

// The duration for each phase of the transition
// So the total duration will be _twice_ this
const timeout = 600;

const defaultStyle = {
  transition: `opacity ${timeout}ms ${EASE}`,
}

const getFadeStyles = () => {
  return {
    entering: {
      zIndex: 777,
      position: 'absolute',
      opacity: 1
    },
    entered: {
      opacity: 1
    },
    exiting: {
      opacity: 0
    },
    exited: {
      opacity: 0
    }
  }
}

const Transition = ({ children, location, currentId }) => {

  // maybe we pass the `status` to the page children
  //const childrenWithProps = React.Children.map(children, child =>
  //  React.cloneElement(child, {
  //    searchState
  //  }),
  //)

  return (
    <TransitionGroup>
      <ReactTransition
        key={location.pathname}
        timeout={timeout}
      >
        {(status) =>
          <div
            style={{
              ...defaultStyle,
              ...getFadeStyles(currentId)[status]
            }}
          >{children}</div>
      }
      </ReactTransition>
    </TransitionGroup>
  )
};

export default Transition
