import Corner from 'components/Corner'
import { Link as GatsbyLink, navigate } from 'gatsby'
import { AlternateLinksContext } from 'layouts'
import { useScrollPosition } from 'lib/useScrollPosition'
import React, { useContext, useEffect, useState } from 'react'
import { Flipped, Flipper } from 'react-flip-toolkit'
import { useTranslation } from 'react-i18next'
import { connectMenu } from 'react-instantsearch-dom'
import { Box, jsx, Link } from 'theme-ui'
import Categories from './Categories'
import StyledSearchBox from './SearchBox'

const commonStyles = {
  position: 'relative',
  bg: 'black',
  color: 'white',
  // transition: 'all .25s linear'
}

const inverseStyles = {
  position: 'relative',
  bg: 'white',
  color: 'black',
  // transition: 'all .25s linear'
}

const AniCorner = ({ width = '8.0rem', top, left, inverse, large, styles }) => (
  <Box
    sx={{
      width: width,
      height: width,
      overflow: `hidden`,
      position: `relative`,
      backfaceVisibility: 'hidden',
      '&:before': {
        content: '""',
        display: `block`,
        width: `100%`,
        height: `100%`,
        position: `absolute`,
        borderRadius: `${large ? 'large' : 'default'}`,
        ...(top ? { top: 0 } : { bottom: 0 }),
        ...(left ? { left: 0 } : { right: 0 }),
        boxShadow: `${left ? '-' : ''}5.0rem ${top ? '-' : ''}5.0rem 0 0 ${
          inverse ? 'black' : 'white'
        }`,
        ...styles,
      },
    }}
  />
)

const ExpandedSearch = ({
  setMenuState,
  menuState,
  handleClick,
  homeLink,
  language,
  inverse,
  ...props
}) => {
  return (
    <Flipped
      flipId="searchInput"
      // onStart={el => { console.log('onStart: ', el)}}
    >
      <Box
        sx={{
          ...(inverse ? inverseStyles : commonStyles),
          borderRadius: 'default',
          width: '100%', // animated
          height: '2.9rem', // animated
          borderBottomRightRadius: menuState === 'refine' ? '0' : 'default',
        }}
      >
        <Flipped inverseFlipId="searchInput">
          <Box sx={{ position: 'absolute', right: '100%', pr: 1 }}>
            <Link
              variant={inverse ? 'menuLabelInverse' : 'menuLabel'}
              onClick={handleClick}
              as={GatsbyLink}
              to={homeLink}
            >
              ×
            </Link>
          </Box>
        </Flipped>
        <Flipped inverseFlipId="searchInput" flipId="searchCorner" opacity>
          <Box
            sx={{
              // corner that appears/disappears
              opacity: 1,
              position: 'absolute',
              right: '-6.0rem',
              top: '100%',
              pointerEvents: 'none',
            }}
          >
            <AniCorner
              inverse={!inverse}
              left
              top
              styles={{ borderRadius: menuState === 'refine' ? 0 : 'default' }}
            />
          </Box>
        </Flipped>
        <StyledSearchBox setMenuState={setMenuState} hasFocus={menuState === 'search'} />
      </Box>
    </Flipped>
  )
}

// mini searchInput
const Search = ({
  label,
  setMenuState,
  menuState,
  handleClick,
  homeLink,
  language,
  inverse,
  ...props
}) => {
  return (
    <Flipped
      flipId="searchInput"
      // onStart fires...
      // onStart={el => { console.log('onStart: ', el)}}
      // onComplete={el => { console.log('onComplete: ', el)}}
    >
      <Box
        sx={{
          ...(inverse ? inverseStyles : commonStyles),
          ml: 'auto',
          mr: menuState === 'refine' ? '16.0rem' : 0,
          borderRadius: 'default',
          width: '9.6rem',
          height: '2.5rem',
          borderBottomRightRadius: menuState === 'refine' ? '0' : 'default',
          cursor: 'pointer',
          ':hover, :focus': {
            '.menu-label': {
              color: inverse ? '#FFF !important' : '#141414 !important',
            },
          },
        }}
        onClick={handleClick}
      >
        <Flipped inverseFlipId="searchInput">
          <Box sx={{ position: 'absolute', right: '100%', pr: 1 }}>
            <Link
              className="menu-label"
              variant={inverse ? 'menuLabelInverse' : 'menuLabel'}
              onClick={handleClick}
              as={GatsbyLink}
              to={homeLink}
            >
              {label}
            </Link>
          </Box>
        </Flipped>
        <Flipped inverseFlipId="searchInput" flipId="searchCorner" opacity>
          <Box
            sx={{
              // corner that appears/disappears
              opacity: 1,
              position: 'absolute',
              right: '-6.0rem',
              top: '100%',
              pointerEvents: 'none',
            }}
          >
            <AniCorner
              inverse={!inverse}
              left
              top
              styles={{ borderRadius: menuState === 'refine' ? 0 : 'default' }}
            />
          </Box>
        </Flipped>
        <StyledSearchBox setMenuState={setMenuState} hasFocus={menuState === 'search'} />
      </Box>
    </Flipped>
  )
}

const ExpandedRefine = ({
  setMenuState,
  menuState,
  handleClick,
  homeLink,
  language,
  inverse,
  ...props
}) => {
  return (
    <Flipped
      flipId="refine"
      // transformOrigin='top right'
    >
      <Box
        sx={{
          ...(inverse ? inverseStyles : commonStyles),
          mr: '2.5rem',
          mt: '-0.33mm',
          ml: 'auto',
          pl: '0.4rem',
          width: '18.5rem',
          height: 'auto',
          //transition: 'all .5s linear',
          '> ul': {
            transition: 'opacity .05s linear',
            opacity: 1,
          },
          borderBottomLeftRadius: 'default',
          borderTopRightRadius: 'default',
          pointerEvents: 'auto',
        }}
      >
        <Flipped inverseFlipId="refine">
          <Box
            sx={{
              position: 'absolute',
              right: '100%',
              pr: 1,
            }}
          >
            <Link
              variant={inverse ? 'menuLabelInverse' : 'menuLabel'}
              onClick={handleClick}
              as={GatsbyLink}
              to={homeLink}
              hrefLang={language}
            >
              ×
            </Link>
          </Box>
        </Flipped>
        <Categories {...props} isVisible={true} />
        <Flipped inverseFlipId="refine" flipId="searchCorner" opacity>
          <Box
            sx={{
              // corner that appears/disappears
              opacity: 1,
              position: 'absolute',
              left: '4.0rem',
              bottom: '100%',
              pointerEvents: 'none',
            }}
          >
            <AniCorner
              inverse={!inverse}
              left
              styles={{ borderRadius: menuState === 'refine' ? 'default' : '0' }}
            />
          </Box>
        </Flipped>
        <Box sx={{ position: 'absolute', right: '100%', top: 0, pointerEvents: 'none' }}>
          <Corner inverse={!inverse} top />
        </Box>
        <Box sx={{ position: 'absolute', left: '100%', bottom: 0, pointerEvents: 'none' }}>
          <Corner inverse={!inverse} left />
        </Box>
      </Box>
    </Flipped>
  )
}

// Mini
const Refine = ({
  label,
  setMenuState,
  menuState,
  handleClick,
  homeLink,
  language,
  inverse,
  ...props
}) => {
  return (
    <Flipped
      flipId="refine"
      // transformOrigin='top right'
    >
      <Box
        sx={{
          ...(inverse ? inverseStyles : commonStyles),
          mt: '-0.33mm',
          mr: '2.0rem',
          ml: 'auto',
          pl: '0.4rem',
          width: '5.3rem',
          height: '2.5rem',
          //transition: 'all .5s linear',
          '> ul': {
            transition: 'opacity .05s linear',
            opacity: 0,
          },
          borderBottomLeftRadius: 'default',
          cursor: 'pointer',
          ':hover, :focus': {
            '.menu-label': {
              color: inverse ? '#FFF !important' : '#141414 !important',
            },
          },
        }}
        onClick={handleClick}
      >
        <Flipped inverseFlipId="refine">
          <Box
            sx={{
              position: 'absolute',
              right: '100%',
              pr: 1,
            }}
          >
            <Link
              className="menu-label"
              variant={inverse ? 'menuLabelInverse' : 'menuLabel'}
              onClick={handleClick}
              as={GatsbyLink}
              to={homeLink}
              hrefLang={language}
            >{label}
            </Link>
          </Box>
        </Flipped>
        <Categories {...props} isVisible={false} />
        <Box sx={{ position: 'absolute', right: '100%', top: 0, pointerEvents: 'none' }}>
          <Corner inverse={!inverse} top />
        </Box>
        <Box sx={{ position: 'absolute', left: '100%', bottom: 0, pointerEvents: 'none' }}>
          <Corner inverse={!inverse} left />
        </Box>
      </Box>
    </Flipped>
  )
}

const Menu = connectMenu(props => {
  const { path, showIntro, showMenu, aboutPage, site } = props
  const { alternateLinks, language, locale, isDefault } = useContext(AlternateLinksContext)
  const { translations } = site
  const { t, i18n } = useTranslation('commonStyles')

  // get current language about page from refs of base language about page
  let i18nAboutPageLink = aboutPage.i18n_path
  if (!isDefault) {
    i18nAboutPageLink =
      aboutPage.i18n_refs?.find(({ ref }) => ref.i18n_lang === language)?.ref.i18n_path || false
    // we might not have a translation of the about page
    if (!i18nAboutPageLink) i18nAboutPageLink = aboutPage.i18n_path
  }

  useEffect(() => {
    props.refineRef.current = refinRefFunc
  }, [])

  const refinRefFunc = value => {
    props.refine(value)
  }

  const [menuState, setMenuState] = useState('')

  const isAbout = props.location.pathname.includes(i18nAboutPageLink)
  const [inverse, setInverse] = useState(false)
  const [scrollingUp, setScrollingUp] = useState(false)

  useEffect(() => {
    if (path === '/' || path === '/de/' || path === '/el/') {
      // index page
    } else {
      setMenuState('')
    }

    if (inverse === true && !isAbout) {
      setInverse(false)
    }
  }, [path])
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const scrollingDown = currPos.y > prevPos.y
      if (!scrollingDown) {
        setScrollingUp(true)
      } else {
        setScrollingUp(false)
      }

      if (isAbout) {
        if (currPos.y >= 150) {
          setInverse(true)
        } else {
          setInverse(false)
        }
      }
    },
    [isAbout]
  )

  // console.log('i18n: ', i18n)

  // use the path to detect what language we currently are on
  // en_GB has no path
  const homeLink = locale === 'en' || locale === undefined ? '/' : `/${locale}`
  // const home = i18n.language === 'en_GB' :

  const handleRefineClick = e => {
    e.preventDefault()
    if (menuState !== 'refine') {
      if (path !== homeLink) {
        setTimeout(() => navigate(homeLink), 500)
        setTimeout(() => setMenuState('refine'), 1000)
      } else {
        setMenuState('refine')
      }
    } else {
      props.refine()
      setMenuState('')
    }
  }

  const handleSearchClick = e => {
    e.preventDefault()
    if (menuState !== 'search') {
      setMenuState('search')
      props.refine()
      if (path !== homeLink) {
        setTimeout(() => navigate(homeLink), 500)
      }
    } else {
      setMenuState('')
    }
  }

  const handleAboutClick = e => {
    e.preventDefault()
    navigate(i18nAboutPageLink)
  }

  return (
    <Flipper
      flipKey={menuState}
      decisionData={menuState}
      staggerConfig={{
        // the "default" config will apply to staggered elements without explicit keys
        default: {
          // default direction is forwards
          reverse: false,
          // default is .1, 0 < n < 1
          // speed: .5
        },
      }}
      spring={{ stiffness: 400, damping: 65 }}
    >
      <Box
        sx={{
          // if the Menu was actually nested in the page, we could simply use position absolute, but we can't, so let's just fade it in first
          // also this is similar logic to hiding the menu durning header transitions
          opacity: showIntro || !showMenu ? 0 : 1,
          transition: 'opacity .25s linear',
          position: 'fixed',
          top: '11px',
          right: 2,
          zIndex: 998,
          variant: 'text.frl',
          '.menu-label': {
            whiteSpace: 'nowrap',
            transition: 'opacity .25s linear',
            opacity: scrollingUp ? 1 : 0,
            pointerEvents: 'none',
          },
          ':hover, :focus': {
            '.menu-label': {
              opacity: 1,
              pointerEvents: 'auto',
            },
          },
        }}
      >
        <Box sx={{ width: '32rem', marginBottom: '3.0rem' }}>
          {menuState === 'search' ? (
            <ExpandedSearch
              setMenuState={setMenuState}
              menuState={menuState}
              handleClick={handleSearchClick}
              homeLink={homeLink}
              language={language}
              inverse={inverse}
            />
          ) : (
            <Search
              label={translations?.search?.localized}
              setMenuState={setMenuState}
              menuState={menuState}
              handleClick={handleSearchClick}
              homeLink={homeLink}
              language={language}
              inverse={inverse}
            />
          )}
          {menuState === 'refine' ? (
            <ExpandedRefine
              {...props}
              setMenuState={setMenuState}
              menuState={menuState}
              handleClick={handleRefineClick}
              homeLink={homeLink}
              language={language}
              inverse={inverse}
            />
          ) : (
            <Refine
              {...props}
              label={translations?.initiatives?.localized}
              setMenuState={setMenuState}
              menuState={menuState}
              handleClick={handleRefineClick}
              homeLink={homeLink}
              language={language}
              inverse={inverse}
            />
          )}
          <Flipped flipId="about">
            <Box
              sx={{
                ...(inverse ? inverseStyles : commonStyles),
                ml: 'auto',
                mt: '-0.33mm',
                width: '4.8rem',
                height: '2.5rem',
                borderTopRightRadius: 'default',
                borderBottomLeftRadius: 'default',
                borderBottomRightRadius: 'default',
                cursor: 'pointer',
                ':hover, :focus': {
                  '.menu-label': {
                    color: inverse ? '#FFF !important' : '#141414 !important',
                  },
                },
              }}
              onClick={handleAboutClick}
            >
              <Box sx={{ position: 'absolute', right: '100%', pr: 1 }}>
                <Link
                  className="menu-label"
                  variant={inverse ? 'menuLabelInverse' : 'menuLabel'}
                  sx={{
                    opacity: menuState === 'search' ? 0 : 1,
                  }}
                  as={GatsbyLink}
                  to={i18nAboutPageLink}
                >
                  {translations?.about?.localized}
                </Link>
              </Box>
              <Box sx={{ position: 'absolute', right: '100%', top: 0, pointerEvents: 'none' }}>
                <Corner inverse={!inverse} top />
              </Box>
            </Box>
          </Flipped>
        </Box>
      </Box>
    </Flipper>
  )
})

export default Menu
