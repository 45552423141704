import { withModalTheme } from '@mattjennings/react-modal'

export const defaultColors = {
  white: '#fff',
  black: '#141414',
  transparent: 'transparent',
  gray: [
    '#F7F7F7', // 0 index
    '#C4C4C4',
    '#949494'
  ],
  cyanLight: '#1390DB'
}

export const colors = {
  text: defaultColors.black,
  background: defaultColors.white,
  secondary: defaultColors.gray[0],
  invertedText: defaultColors.gray[1],
  selection: defaultColors.cyanLight,
  link: defaultColors.gray[2],
  ...defaultColors
}

export const space = [0, 5, 10, 15, 20, 25, 30, 50, 100, 150].map(n => n + 'px')

// these breakpoints are min-width I believe
export const breakpoints = ['320px', '768px', '1280px', '1680px', '2560px']

// breakpoints: [
// '40em', '@media (min-width: 56em) and (orientation: landscape)', '64em',
// ]

/*
sx={{
  width: [null, null, '25%'],
}}

*OR*

sx={{
  width: '100%',
  '@media screen and (min-width: 40em)': {
    width: '50%',
  },
}}
*/


export const fonts = {
  body: '"ABCFavoritPro-Book", Arial, sans-serif',
  italic: '"ABCFavoritPro-BookItalic", Arial, sans-serif',
  mono: '"ABCFavoritMono-Regular", Arial, sans-serif',
  monoItalic: '"ABCFavoritMono-RegularItalic", Arial, sans-serif',
  heading: 'inherit'
}

// no greek version for the mono - fallback to Favorit Pro Book

export const fontWeights = {
  body: 400,
  heading: 400,
  bold: 400,
  normal: 0,
}
fontWeights.normal = fontWeights.body

export const fontSizes = [
  12, // 0
  16, // 1
  22, // 2
  36, // 3
  100, // 4
  150, // 5
  250
]

export const lineHeights = {
  frs: '15px',
  frm: '20px',
  frl: '25px',
  frxl: '40px',
  frxxl: '100px',
  frxxxl: '140px',
  frxxxxl: '220px'
}

export const letterSpacings = {
  // normal: '-0.05px',
  small: '0',
  body: '0',
  heading: '0',
  caps: '0',
  frs: '0.02em'
}

export const text = {
  default: {
    color: 'text',
    fontSize: 3,
    lineHeight: 'frl'
  },
  frs: {
    fontSize: 0,
    lineHeight: 'frs',
  },
  frm: {
    fontSize: 1,
    lineHeight: 'frm',
  },
  frl: {
    fontSize: 2,
    lineHeight: 'frl',
  },
  frxl: {
    fontSize: 3,
    lineHeight: 'frxl',
  },
  frxxxl: {
    fontSize: 5,
    lineHeight: 'frxxxl'
  },
  frxxxxl: {
    fontSize: 6,
    lineHeight: 'frxxxxl'
  },
  fms: {
    fontFamily: 'mono',
    fontSize: 0,
    lineHeight: 'frs'
  }
}


export const radii = {
  default: '0.5rem',
  small: '',
  large: '1.0rem',
  pill: '9999px',
  circle: '',
}
radii.small = radii.default
radii.circle = radii.pill

export const buttons = {
  primary: {
    variant: 'text.frm',
    fontFamily: 'body',
    padding: '2px 5px',
    lineHeight: '17px',
    color: 'text',
    bg: 'transparent',
    border: '1px solid',
    borderColor: 'black',
    borderRadius: 'default',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 'none',
    userSelect: 'none',
    width: 'auto',
    transition: '.2s ease',
    transitionProperty: 'color, background-color, border-color',
    ':hover, :focus': {
      color: 'cyanLight', // blue
      borderColor: 'cyanLight', // blue
    },
    ':visited': {
      color: 'text',
    }
  },   // used in ReadMore
  inverted: {
    variant: 'buttons.primary',
    color: 'invertedText',
    borderColor: 'invertedText',
    ':hover, :focus': {
      color: 'cyanLight', // blue
      borderColor: 'cyanLight', // blue
    },
    ':visited': {
      color: 'invertedText'
    }
  },
}

export const links = {
  number: {
    bg: 'black',
    color: 'white',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: 'transparent',
    verticalAlign: 'top',
    ml: '3px',
    fontSize: 0,
    width: '20px',
    height: '20px',
    lineHeight: '21px',
    display: 'inline-block',
    textAlign: 'center',
    '&:visited': { color: 'white' },
    '&:hover, &:focus': {
      color: 'black',
      bg: 'white',
      borderColor: 'black'
    },
  },
  popOver: {
    cursor: 'pointer',
    color: 'invertedText',
    ':hover, :focus': {
      color: 'cyanLight',
    }
  },
  text: {
    color: 'link',
    textDecoration: 'none',
    cursor: 'pointer',
    alignItems: 'center',
    fontSize: 'inherit',
    WebkitTouchCallout: 'none',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    transition: '.2s ease',
    transitionProperty: 'color',
    ':hover, :focus': {
      textDecoration: 'none',
      color: 'cyanLight'
    },
    ':visited': {
      color: 'link',
      ':hover, :focus': {
        color: 'cyanLight'
      }
    }
  },
  inverted: {
    cursor: 'pointer',
    color: 'link',
    ':hover, :focus': {
      color: 'white',
    },
    ':visited': {
      color: 'link',
      ':hover, :focus': {
        color: 'white'
      }
    }
  },
  menuLabel: {
    variant: 'links.text',
    color: 'link',
    ':visited': {
      color: 'link'
    },
    ':hover, :focus': {
      color: '#141414 !important',
    }
  },
  menuLabelInverse: {
    variant: 'links.text',
    color: 'link',
    ':visited': {
      color: 'link'
    },
    ':hover, :focus': {
      color: '#FFF !important',
    }
  },
  button: {
    variant: 'buttons.primary'
  },
  buttonInverted: { // used in Footer
    variant: 'links.button',
    color: 'invertedText',
    borderColor: 'invertedText',
    ':hover, :focus': {
      color: 'white',
      borderColor: 'white',
    },
    ':visited': {
      color: 'invertedText',
      ':hover, :focus': {
        color: 'white'
      }
    }
  },
}

export const modals = {
  backdrop: {
    zIndex: 999,
    position: `fixed`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `transparent`,
  },
  footer: {
    fontSize: 1,
    position: 'absolute',
    top: '2px',
    right: 0,
  },
  title: {
    // display: 'flex',
    // justifyContent: 'space-between',
    // alignItems: 'left',
    ...text.fms,
    color: 'black',
    position: 'absolute',
    zIndex: '111',
    top: '0',
    left: '0',
    maxWidth: '50%',
    pr: 1,
    // borderTop: 'none',
    borderBottomRightRadius: 'default',
    borderTopLeftRadius: 'default',
    backgroundColor: 'white',
  },
  default: { // inner wrapper
    gridColumn: ['1/5', null, '4/7', '9/13', '10/13', '11/13'],
    display: `flex`,
    padding: '0 !important',
    flexDirection: `column`,
    bg: 'transparent !important',
    maxHeight: `80vh`,
    minHeight: '16rem',
    width: `100% !important`,
    position: `absolute`,
    top: [`10px`, null, `125px`],
    zIndex: 999,
    color: 'white'
  }
}

export const styles = {
  root: {
    fontFamily: 'body',
    bg: 'white',
    color: 'text',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    textRendering: 'optimizeLegibility',
    fontSize: '10px',
    lineHeight: '1.5',
    margin: 0,
    padding: 0,
    minHeight: '100%',
    position: 'relative',
    overflowX: 'hidden',
    '& ::selection': {
      bg: 'selection',
      color: 'text',
    },
    // these styles shouldn't need to be nested inside 'root' but it only works this way
    body: {
      fontSize: '2.2rem',
      lineHeight: 'frl'
    },
    article: {
      flexGrow: '1'
    },
    a: {
      variant: 'links.text',
    },
    'a figcaption': {
      color: 'black'
    },
    'aside a': {
      borderRadius: 'default',
      p: '1px 4px 0',
      color: 'black',
      bg: 'secondary',
      ':visited': {
        color: 'black'
      },
      ':hover, :focus': {
        ...text.fms,
        color: 'white',
        bg: 'black'
      },
      '&.current': {
        color: 'white',
        bg: 'black',
      },
    },
    'h1,h2,h3,h4,h5,h6': {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'frxl',
      whiteSpace: 'pre-wrap',
      mt: 0,
      mb: '0.625rem',
      color: 'inherit',
    },
    'h1': {
      fontFamily: 'heading',
      fontSize: 4,
      lineHeight: 'frxxl',
      mb: '1rem',
      // mt: 0,
      // mb: '0.625rem',
      color: 'inherit',
    },
    'h2,h3,h4,h5': {
      fontFamily: 'mono',
      fontSize: 0,
      lineHeight: 'frs',
      mb: '0.5rem',
    },
    '.gatsby-image-wrapper': {
      borderRadius: 'default',
      // fixes safari border radius bug
      // https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
      overflow: 'hidden',
      backfaceVisibility: 'hidden',
      transform: 'translate3d(0, 0, 0)'
    },
    '.gatsby-image-wrapper img': {
      transition: 'opacity .25s linear, transform .25s ease-in-out !important',
      willChange: 'transform opacity !important'
    },
    figure: {
      margin: 0,
    },
    figcaption: {
      fontFamily: 'mono',
      fontSize: 0,
      lineHeight: 'frs',
      mt: 1,
      mb: 0
    },
    'p em, blockquote em': {
      fontFamily: 'italic',
      fontStyle: 'normal'
    },
    'blockquote': {
      my: 2,
      mx: 0
    },
    '.small-p p': {
      fontFamily: 'body',
      fontSize: 1,
      lineHeight: 'frm'
    },
    'figcaption em': {
      fontFamily: 'monoItalic'
    },
    strong: {
      fontWeight: 'normal',
      textTransform: 'uppercase'
    },
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none'
    },
    '.richText ul': {
      listStyle: 'circle',
      listStylePosition: 'inside'
    },
    p: {
      position: 'relative',
      my: 0
    },
    '.block-text p': {
      mb: 1
    },
    '.css-grid, .css-col-grid': {
      display: 'grid',
      gridTemplateColumns: ['repeat(4, 1fr)', null, 'repeat(6, 1fr)', 'repeat(12, 1fr)'],
      gap: '10px',
      'p, h4, h3, .large-text, ul, ol': {
        gridColumn: ['1/5', null, null, '2/9', '2/8'],
        mb: 2
      },
      'p:last-of-type, ul:last-of-type': {
        mb: 5
      },
      '.pageRichTextModule p:last-of-type': {
        mb: 2
      },
      'ul + h4, p + h4': {
        mt: 3
      },
      'h3, h4': {
        //mt: 4,
        mb: 1
      },
      '&.grid-start': {
        alignItems: 'start'
      },
      '&.grid-end': {
        alignItems: 'end'
      }
    },
    '.css-col-grid': {
      gridRowGap: 0
    }
  },
}

export const layout = {
  container: {
    maxWidth: '2560px',
    // padding: 1.5,
    margin: '0 auto'
  }
}

export const forms = {
  input: {
    variant: 'text.frl',
    px: 1,
    m: 0,
    verticalAlign: 'middle',
    alignItems: 'center',
    borderRadius: 'default',
    bg: 'transparent',
    height: '29px',
    width: '100%',
    outline: 0,
    border: '1px solid',
    borderColor: 'transparent',
    WebkitAppearance: 'none',
    transition: 'border .2s ease,color .2s ease',
    ':focus': {
      borderColor: 'transparent',
    },
    ':disabled': {
      bg: 'transparent',
      cursor: 'not-allowed',
    },
    ':-webkit-autofill': {
      boxShadow: 'inset 0 0 0 100px #fff',
    },
    '::-webkit-input-placeholder': {
      color: 'gray.2',
    },
    '::-moz-placeholder': {
      color: 'gray.2',
    },
    ':-ms-input-placeholder': {
      color: 'gray.2',
    },
    '&[type="search"]::-webkit-search-decoration': {
      display: 'none',
    },
  },
  inverted: {
    variant: 'forms.input',
    borderColor: 'transparent',
  },
  /*
  disabled: {
    variant: 'forms.input',
    bg: 'transparent',
    cursor: 'not-allowed',
  },
  textarea: {
    variant: 'forms.input',
    height: 'auto',
    minHeight: '4.625rem',
    lineHeight: '1.5rem',
  },
  select: {
    variant: 'forms.input',
    'input:checked ~ &': {
      color: 'text',
    },
  },
  radio: {
    color: 'gray.4',
    'input:checked ~ &': {
      color: 'text',
    },
  },
  checkbox: {
    color: 'gray.4',
  },
  small: {
    variant: 'forms.input',
    fontSize: '0.75rem',
    height: '2rem',
    lineHeight: '1.375rem',
  },
  large: {
    variant: 'forms.input',
    fontSize: '1rem',
    height: '2.625rem',
    lineHeight: '2.375rem',
  },
  error: {
    variant: 'forms.input',
    borderColor: 'error',
    color: 'error',
  },
  success: {
    variant: 'forms.input',
    borderColor: 'success',
    color: 'success',
  },
  label: {
    color: 'text',
    textAlign: 'left',
    fontSize: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  labelHoriz: {
    color: 'text',
    textAlign: 'left',
    fontSize: 1,
    display: 'flex',
  },
  slider: {
    bg: 'gray.2',
    color: 'success',
  },
  hidden: {
    position: 'absolute',
    height: '1px',
    width: '1px',
    overflow: 'hidden',
    clip: 'rect(1px, 1px, 1px, 1px)',
    whiteSpace: 'nowrap',
  },
  */
}

export const schwarz = {
  breakpoints,
  colors,
  space,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  text,
  // sizes,
  // shadows,
  radii,
  modals,
  styles,
  // variants =====
  links,
  // text,
  // avatars,
  // badges,
  buttons,
  // cards,
  forms,
  layout,
}

export default withModalTheme(schwarz)
