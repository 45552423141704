const getResponsiveStyles = (property, styles) => ({
  ['@media (min-width: 0px)']: {
    ...(styles[0] !== null && {[property]: styles[0]})
  },
  ['@media (min-width: 320px)']: {
    ...(styles[1] !== null && {[property]: styles[1]})
  },
  ['@media (min-width: 768px)']: {
    ...(styles[2] !== null && {[property]: styles[2]})
  },
  ['@media (min-width: 1280px)']: {
    ...(styles[3] !== null && {[property]: styles[3]})
  },
  ['@media (min-width: 1680px)']: {
    ...(styles[4] !== null && {[property]: styles[4]})
  },
  ['@media (min-width: 2560px)']: {
    ...(styles[5] !== null && {[property]: styles[5]})
  }
})

export default getResponsiveStyles
