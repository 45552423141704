import { Box } from 'theme-ui'

const Corner = ({ width = '8.0rem', top, left, inverse, large }) => <Box sx={{
  width: width,
  height: width,
  overflow: `hidden`,
  position: `relative`,
  backfaceVisibility: 'hidden',
  '&:before': {
    content: '""',
    display: `block`,
    width: `100%`,
    height: `100%`,
    position: `absolute`,
    borderRadius: `${large ? 'large' : 'default'}`,
    ...(top ? { top: 0 } : { bottom: 0 }),
    ...(left ? { left: 0 } : { right: 0 }),
    boxShadow: `${left ? '-' : ''}5.0rem ${top ? '-' : ''}5.0rem 0 0 ${inverse ? 'black' : 'white'}`,
  }
}}/>

export default Corner


function getCorner(top = '', right = '', bottom = '', left = '', inverse) {
  switch(true) {
    case top.length > 0 && left.length > 0: // TOP LEFT
      return { boxShadow: `-5.0rem -5.0rem 0 0 ${inverse ? 'black' : 'white'}`}
    case top.length > 0 && right.length > 0: // TOP RIGHT
      return { boxShadow: `5.0rem -5.0rem 0 0 ${inverse ? 'black' : 'white'}`}
    case bottom.length > 0 && right.length > 0: // BOTTOM_RIGHT
      return { boxShadow: `5.0rem 5.0rem 0 0 ${inverse ? 'black' : 'white'}`}
    case bottom.length > 0 && left.length > 0: // BOTTOM LEFT
      return { boxShadow: `-5.0rem 5.0rem 0 0 ${inverse ? 'black' : 'white'}`}
    default: // TOP LEFT
      return { boxShadow: `-5.0rem -5.0rem 0 0 ${inverse ? 'black' : 'white'}`}
  }
}

// large ? '12px' : '6px'
export const CornerV2 = ({
  width = '6rem',
  top,
  right,
  bottom,
  left,
  mt,
  mr,
  mb,
  ml,
  inverse,
  large // will change the width
}) =>
  <Box sx={{
    position: 'absolute',
    bottom,
    right,
    left,
    top,
  }}>
    <Box
      sx={{
        position: 'absolute',
        overflow: `hidden`,
        position: `relative`,
        width,
        height: width,
        pointerEvents: 'none',
        '&:before': {
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'absolute',
          borderRadius: `${large ? 'large' : 'default'}`,
          top: top ? 0 : 'unset',
          left: left ? 0 : 'unset',
          right: right ? 0 : 'unset',
          bottom: bottom ? 0 : 'unset',
          mt,
          mr,
          mb,
          ml,
          ...(getCorner(top, right, bottom, left, inverse))
        }
      }}
     />
  </Box>

