/** @jsx jsx */
import { CornerV2 as Corner } from 'components/Corner'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'lib/getGatsbyImageProps'
import get from 'lodash.get'
import React, { useContext } from 'react'
import { Box, jsx, Text } from 'theme-ui'

function sizeToWidth(size) {
  switch (true) {
    case size === 'large':
      return { 'width': '83%' }
    case size === 'medium':
      return { 'width': '75%' }
    default:
      return { 'width': '75%' }
  }
}

function categorySizeToWidth(size) {
  switch (true) {
    case size === 'large':
      return { 'width': ['33.3333%', null, '16.6666%'] }
    case size === 'medium':
      return { 'width': ['33.3333%', null, '16.6666%'] }
    default:
      return { 'width': '33.3333%' }
  }
}


const Category = ({size, icon, category}) =>
  <Box
    className='category'
    sx={{
      transition: 'transform .25s',
      // backfaceVisibility: 'hidden',
      transform: 'translateY(calc(-100% - 1px))',
      // @TODO: this seems tricky... needs to be set per initial tile size
      // and we get this odd space below the image, would maybe need to use
      // the aspect ratio to set a pixel height?
      ...(categorySizeToWidth(size)),
      // width: ,
      // width: '76px',
      // height: '50px',
      position: 'absolute',
      top: '-0.3mm',
      left: '-0.3mm',
      zIndex: 20,
      'img': {
        mt: '0.3mm',
        ml: '0.3mm',
        // backfaceVisibility: 'hidden',
        display: 'block',
        borderBottomRightRadius: 'default',
      }
    }}
  >
  <img width='100%' height='auto' src={icon} />
  <Corner top='100%' left='0' ml='0.5mm' />
  <Text
    variant='fms'
    sx={{
      position: 'absolute',
      top: 0,
      left: 'calc(100% - 0.3mm)',
      bg: 'white',
      p: '3pt 5pt 0',
      color: 'black',
      whiteSpace: 'nowrap',
      borderRadius: 'none',
      borderBottomRightRadius: 'default',
    }}
  >
    {category}
    <Corner top='100%' left='0' ml='0.5mm' />
    <Corner top='0' left='100%' mt='0.5mm' />
  </Text>
</Box>

const Caption = ({size, title}) =>
  <figcaption sx={{
    // backfaceVisibility: 'hidden',
    transition: 'opacity .5s, transform .25s',
    transform: 'translate(0)',
    opacity: 1,
    position: 'absolute',
    zIndex: 20,
    bottom: 0,
    right: 0,
    ...(sizeToWidth(size)),
    p: '3pt 5pt 0',
    mr: '-0.3mm',
    mb: '-0.3mm',
    borderTopLeftRadius: 'default',
    borderBottom: 'none',
    bg: 'white'
  }}>
    {/* Top right corner of caption */}
    <Corner bottom='100%' right='0' mr='0.3mm' mb='0' />
    {/* Bottom left corner of caption */}
    <Corner bottom='0' right='100%' mb='0.3mm' ml='-0.3mm' />
    {`${title}`}
  </figcaption>

const InitiativeTile = ({ mainImage, i18n_path, i18n_lang, slug = {}, title, size, categories }) => {
  const icon = get(categories[0], 'invertedIcon.asset.url')
  const category = get(categories[0], 'title.localized')
  return (
    <Link
      to={i18n_path}
      hrefLang={i18n_lang}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        display: 'block',
        ':hover, :focus': {
          '.category': { opacity: 1, transform: 'translateY(0)' },
          'figcaption': { opacity: 1, transform: 'translateY(100%)' },
          '.gatsby-image-wrapper img': {
            transform: 'scale(1.05)',
          }
        }
      }}
    >
      { category && <Category size={size} icon={icon} category={category} /> }
      <figure sx={{ position: 'relative',
        border: '1px solid white'
      }}>
        { mainImage && mainImage.asset ?
        <GatsbyImage image={getGatsbyImageData(mainImage, { hotspot: mainImage.hotspot, tile: true, placeholder: 'dominantColor' })} alt={mainImage.alt} /> : <img width="100%" height="auto" src="https://via.placeholder.com/800x450" alt="placeholder"/>
        }
        <Caption size={size} title={title} />
      </figure>
    </Link>
  )
}

export default InitiativeTile
