/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { Text, Box } from 'theme-ui'

const Footnote = ({id, text, number}) => {
  return (
    <div
      id={`${id}`}
      sx={{
        position: 'relative',
        pl: 5,
        mb: 2,
        pt: '10px',
        minHeight: '20px',
      }}
    >
      <Text
        variant='frs'
        sx={{
          position: 'absolute',
          top: 1,
          left: 0,
          bg: 'black',
          color: 'white',
          borderRadius: '50%',
          border: '1px solid',
          borderColor: 'transparent',
          fontSize: 0,
          width: '20px',
          height: '20px',
          lineHeight: '21px',
          display: 'inline-block',
          textAlign: 'center'
        }}
      >
        {number}
      </Text>
      <Text variant='fms'>
        <BlockContent blocks={text}/>
      </Text>
    </div>
  )
}


const SimpleFootnotes = ({ blocks }) => {
  const notes = blocks && blocks
    // filter out everything that's not a text block
    .filter(({ _type }) => _type === 'block')
    // make an array of the mark definitions of those blocks
    .reduce((acc, curr) => {
      return [...acc, ...curr.markDefs];
    }, [])
    // find all the footnote mark definitions
    .filter(({ _type }) => _type === 'footnote') || []

  return (
    <Box sx={{
      width: '75%'
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        {notes.map(({ _key, text }) => {
          const number = notes.findIndex(note => note._key === _key) + 1
          return <Footnote id={_key} key={_key} text={text} number={number} />
        })
      }
      </Box>
    </Box>
  )
}

export default SimpleFootnotes
