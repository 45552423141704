/** @jsx jsx */
import BlockContent from '@sanity/block-content-to-react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'lib/getGatsbyImageProps'
import useResponsiveConfig from 'lib/useResponsiveConfig'
import get from 'lodash.get'
import React from 'react'
import { jsx } from 'theme-ui'

const moduleMapping = [
  {
    imageCount: 1,
    first: 'landscape',
    second: null,
    module: '02.1',
  },
  {
    imageCount: 1,
    first: 'portrait',
    second: null,
    module: '02.2',
  },
  {
    imageCount: 2,
    first: 'landscape',
    second: 'landscape',
    module: '03.1',
  },
  {
    imageCount: 2,
    first: 'landscape',
    second: 'portrait',
    module: '03.2',
  },
  {
    imageCount: 2,
    first: 'portrait',
    second: 'landscape',
    module: '03.3',
  },
  {
    imageCount: 2,
    first: 'portrait',
    second: 'portrait',
    module: '03.4',
  },
]

const responsiveConfig = [
  {
    // 320
    '02.1': [{ gridColumn: '1/5' }],
    '02.2': [{ gridColumn: '1/4' }],
    '03.1': [{ gridColumn: '1/5' }, { gridColumn: '1/5' }],
    '03.2': [{ gridColumn: '1/5' }, { gridColumn: '1/4' }],
    '03.3': [{ gridColumn: '1/4' }, { gridColumn: '1/5' }],
    '03.4': [{ gridColumn: '1/4' }, { gridColumn: '1/4' }],
  },
  null,
  {
    // 768
    '02.1': [{ gridColumn: '1/6' }],
    '02.2': [{ gridColumn: '1/4' }],
    '03.1': [{ gridColumn: '1/4' }, { gridColumn: '4/7' }],
    '03.2': [{ gridColumn: '1/4' }, { gridColumn: '4/6' }],
    '03.3': [{ gridColumn: '1/3' }, { gridColumn: '4/6' }],
    '03.4': [{ gridColumn: '1/3' }, { gridColumn: '3/5' }],
  },
  {
    // 1280
    '02.1': [{ gridColumn: '1/9' }],
    '02.2': [{ gridColumn: '1/6' }],
    '03.1': [{ gridColumn: '3/8' }, { gridColumn: '8/13' }],
    '03.2': [{ gridColumn: '3/8' }, { gridColumn: '8/12' }],
    '03.3': [{ gridColumn: '3/7' }, { gridColumn: '7/12' }],
    '03.4': [{ gridColumn: '3/7' }, { gridColumn: '7/11' }],
  },
  null,
  {
    // 2560
    '02.1': [{ gridColumn: '2/9' }],
    '02.2': [{ gridColumn: '1/6' }],
    '03.1': [{ gridColumn: '3/7' }, { gridColumn: '7/11' }],
    '03.2': [{ gridColumn: '3/6' }, { gridColumn: '6/10' }],
    '03.3': [{ gridColumn: '3/6' }, { gridColumn: '6/10' }],
    '03.4': [{ gridColumn: '3/6' }, { gridColumn: '6/9' }],
  },
]

const ImagesModule = ({ node = {} }) => {
  // console.log('ImagesModule node.images: ', node.images)
  let prevFormat
  return (
    <div sx={{ gridColumn: ['1/5', null, '1/7', '1/13'], my: 7 }}>
      <div className="css-grid">
        {(node.images || []).map((image, index) => {
          const { width, height, aspectRatio } = get(image, 'asset.metadata.dimensions', {})

          const getOrientation = image => {
            if (image === null) {
              return null
            }
            const dimensions = get(image, 'asset.metadata.dimensions', {})
            return dimensions.width < dimensions.height ? 'portrait' : 'landscape'
          }
          const firstImage = index === 0 ? image : node.images[index - 1]
          let secondImage = null
          if (node.images.length > 1) {
            secondImage = index === 0 ? node.images[index + 1] : image
          }
          const getModule = (imageCount, first, second) => {
            return moduleMapping.find(
              item =>
                item.first === first && item.second === second && item.imageCount === imageCount
            )
          }
          const moduleFound = getModule(
            node.images.length,
            getOrientation(firstImage),
            getOrientation(secondImage)
          )
          const currentConfig = useResponsiveConfig(responsiveConfig)
          const responsiveGridItem = currentConfig[moduleFound.module][index]
          return (
            <figure key={image._key} sx={responsiveGridItem}>
              <GatsbyImage
                image={getGatsbyImageData(image, { width: 1480, placeholder: 'dominantColor' })}
                alt={image.alt || image.asset?.altText}
              />
              {image.blockCaption ? (
                <figcaption
                  sx={{
                    'span, div, p': { display: 'inline' },
                  }}
                >
                  <span>↑ </span>
                  <BlockContent renderContainerOnSingleChild blocks={image.blockCaption} />
                </figcaption>
              ) : (
                <figcaption />
              )}
            </figure>
          )
        })}
      </div>
    </div>
  )
}

export default ImagesModule
