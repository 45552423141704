/** @jsx jsx */
import InitiativeTile from 'components/InitiativeTile'
import { CommonTranslationsContext } from 'layouts'
import { sizeToCol } from 'lib/helpers'
import React, { useContext } from 'react'
import { Box, Heading, jsx } from 'theme-ui'

function Related({ nodes }) {
  const { relatedInitiatives } = useContext(CommonTranslationsContext)
  return (
    <Box className="css-grid" sx={{ mt: 9, mb: 8 }}>
      <Heading variant="frxl" sx={{ gridColumn: ['1/5', null, '2/7', '3/9'] }}>
        {relatedInitiatives?.localized}
      </Heading>
      <Box sx={{ gridColumn: '1/13' }}>
        <ul className="css-grid grid-start" sx={{ listStyle: 'none', p: 0, m: 0 }}>
          {nodes &&
            nodes.map((node, position) => (
              <li
                key={node.id}
                sx={{
                  ...sizeToCol(node.size),
                  alignSelf: position < nodes.length / 2 ? 'end' : 'start',
                }}
              >
                <InitiativeTile {...node} />
              </li>
            ))}
        </ul>
      </Box>
    </Box>
  )
}

export default Related
