import React from 'react'
import { Box } from 'theme-ui'
import { useCarousel, makeIndices } from './carouselHook'
import Arrows from 'components/Arrows'

const DEFAULT_WIDTH = '64em' // 1024px
const MIN_DEFAULT_MQ = `@media (min-width:${DEFAULT_WIDTH})`


const Carousel = ({ slides, edges = [0,0], clones = 2, gutters = 0 }) => {
  // const slides = React.Children.toArray(children);
  // somehow we need to get the actual width of each slide
  // react-multi-carousel has a single item width, not for all items
  const widths = slides.map(({ width }) => width)

  if (clones >= slides.length) {
    clones = slides.length - 1
  }
  // console.log('slides: ', slides.length)
  // console.log('clones: ', clones)
  const widthsWithClones = [...widths]
  // clone widths array 2 times to being able to clone items and append/prepend them
  // to the widthsWithClones array
  const copiedWidths = [...widths]
  const copiedWidthsReverse = [...widths].reverse()
  for (const index of Array(clones)){
    widthsWithClones.unshift(copiedWidths.pop())
    // 1st element of reversed array is last of the main width array
    widthsWithClones.push(copiedWidthsReverse.pop())
  }
  const totalWidthWithClones = widthsWithClones.reduce((acc, width) => acc + width, 0)
  const totalWidth = widths.reduce((acc, width) => acc + width, 0)
  const length = slides.length;
  const numActive = Math.min(length, clones);
  const {active, setActive, handlers, style, prev, next} = useCarousel(length, { numActive, widths, widthsWithClones, totalWidthWithClones, totalWidth });

  const beforeIndices = makeIndices(slides.length - clones, 1, numActive); // without the -2  this fails because of 2 clones (I think)
  const afterIndices = makeIndices(0, 1, numActive);

  // console.log('beforeIndices: ', beforeIndices)
  // console.log('afterIndices: ', afterIndices)

  const beforeSlides = beforeIndices.map(i => (
    <CarouselItem key={`beforeClone-${slides[i].key + i}`} gutters={gutters}>{slides[i].render}</CarouselItem>
  ));

  const actualSlides = slides.map((slide, index) => (
    <CarouselItem key={`${slide.key + index}`} gutters={gutters}>{slide.render}</CarouselItem>
  ));

  const afterSlides = afterIndices.map(i => (
    <CarouselItem key={`afterClone-${slides[i].key + i}`} gutters={gutters}>{slides[i].render}</CarouselItem>
  ));

  const carouselEdges = {
    paddingLeft: `${edges[0] + edges [1]}px`,
    // paddingRight: `${edges[1]}px`,
  };

  // @TODO: update on window resize
  if (typeof window !== "undefined" && totalWidth <= window.innerWidth){
    return !!length && <div className="react-swipeable-carousel__wrapper">
      {actualSlides}
    </div>
  }
  return (
    typeof window !== "undefined" && !!length && (
      <React.Fragment>
        <Arrows
          handlePrevClick={prev}
          handleNextClick={next}
        />
        <Box
          sx={{
            variant: 'text.fms',
            position: 'absolute',
            top: -3,
            left: 1,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {`${active + 1} / ${length}`}
        </Box>
        <div
          className="react-swipeable-carousel"
          style={carouselEdges}
        >
          {/* <ol className={carouselIndicators}>
            {slides.map((_, index) => (
              <li
                onClick={() => setActive(index)}
                key={index}
                className={`${active === index ? 'active' : ''} ${carouselIndicator}`}
              />
            ))}
          </ol> */}
          <div className="react-swipeable-carousel__wrapper" {...handlers} style={style}>
            {beforeSlides}
            {actualSlides}
            {afterSlides}
          </div>
        </div>
      </React.Fragment>
    )
  );
};

const CarouselItem = ({ gutters, children }) => {
  const slideMargin = {
    marginLeft: `${gutters}px`,
    marginRight: `${gutters}px`,
  };

  return <div style={slideMargin}>{children}</div>
}

export default Carousel
