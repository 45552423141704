import {
  generateImageData,
  IGatsbyImageData,
  IGatsbyImageHelperArgs,
  Layout,
} from 'gatsby-plugin-image'
import get from 'lodash.get'
import imageUrlBuilder from '@sanity/image-url'
import clientConfig from '../../client-config'

// https://www.gatsbyjs.com/docs/how-to/plugins-and-themes/adding-gatsby-image-support/
// generateImageData: https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-image/src/image-utils.ts

// export type ImageNode = ImageAsset | ImageObject | ImageRef | string | null | undefined

export const EVERY_BREAKPOINT = [
  320,
  654,
  768,
  1024,
  1366,
  1600,
  1920,
  2048,
  2560,
  3440,
  3840,
  4096,
]
/*

export enum ImageFormat {
  NO_CHANGE = '',
  WEBP = 'webp',
  JPG = 'jpg',
  PNG = 'png',
}

type ImagePalette = {
  darkMuted?: ImagePaletteSwatch
  lightVibrant?: ImagePaletteSwatch
  darkVibrant?: ImagePaletteSwatch
  vibrant?: ImagePaletteSwatch
  dominant?: ImagePaletteSwatch
  lightMuted?: ImagePaletteSwatch
  muted?: ImagePaletteSwatch
}

type ImagePaletteSwatch = {
  background?: string
  foreground?: string
  population?: number
  title?: string
}

type ImageDimensions = {
  width: number
  height: number
  aspectRatio: number
}

type ImageMetadata = {
  palette?: ImagePalette
  dimensions: ImageDimensions
  lqip?: string
}

type ImageAssetStub = {
  url: string
  assetId: string
  extension: string
  metadata: ImageMetadata
}

type ImageAsset = ImageAssetStub & {
  _id: string
}

type ImageRef = {
  _ref: string
}

type ImageObject = {
  asset: ImageRef | ImageAsset
}

export type ImageArgs = {
  maxWidth?: number
  maxHeight?: number
  sizes?: string
  toFormat?: ImageFormat
}

type SanityLocation = {
  projectId: string
  dataset: string
}
*/

const idPattern = /^image-[A-Za-z0-9]+-\d+x\d+-[a-z]+$/

function buildImageUrl(loc, stub, args) {
  const {projectId, dataset} = loc
  const {assetId, extension, metadata} = stub
  const {width: intrinsicWidth, height: intrinsicHeight, aspectRatio} = metadata.dimensions
  const {width, height, tile, hotspot } = args
  const base = 'https://cdn.sanity.io/images'
  const { x: fpX, y: fpY } = hotspot || {}

  // console.log('metadata: ', metadata)
  // console.log('args: ', args)
  // console.log('tile: ', tile)
  // console.log('hotspot: ', hotspot)
  const portrait = aspectRatio <= 1
  let queryWidth = width || intrinsicWidth
  let queryHeight = height || intrinsicHeight
  if (tile) {
    // 16:9 (0,5625)
    // 1260 - 800 = 460
    queryWidth = 1260 // 1260
    queryHeight = 709 // 709
    if (portrait) {
      // 8:9 (0,8888)
      queryWidth = 1260
      queryHeight = 1418
    }
  }

  return `${base}/${projectId}/${dataset}/${assetId}-${intrinsicWidth}x${intrinsicHeight}.${extension}?${queryWidth ? `&w=${queryWidth}` : ''}${queryHeight ? `&h=${queryHeight}` : ''}${fpX ? `&fp-x=${fpX}` : ''}${fpY ? `&fp-y=${fpY}` : ''}`
}

function getBasicImageProps(node, loc, args) {
  if (!node) {
    return false
  }

  // @IMPORTANT
  // we expect `node` to be the image object passed from raw fields or graphql
  // if graphql we need to add the fields with `ImageWithHotspot` fragment

  const obj = node // as ImageObject
  // const ref = node // as ImageRef
  const img = node // as ImageAsset

  let id = ''
  if (typeof node === 'string') {
    id = node
  } else if (obj.asset) {
    id = (obj.asset).id
  } else {
    id = img.id
  }

  const hasId = typeof id !== 'undefined' || idPattern.test(id)
  if (!hasId) {
    return false
  }

  const [, assetId, dimensions, extension] = id.split('-')
  const [width, height] = dimensions.split('x').map((num) => parseInt(num, 10))
  const aspectRatio = width / height
  const metadata = get(img, 'asset.metadata') || {dimensions: {width, height, aspectRatio}}
  const url = img.url || buildImageUrl(loc, {url: '', assetId, extension, metadata}, args)

  return {
    url,
    assetId,
    extension,
    metadata,
  }
}

const fitMap = new Map([
  [`clip`, `inside`],
  [`crop`, `cover`],
  [`fill`, `contain`],
  [`fillmax`, `contain`],
  [`max`, `inside`],
  [`scale`, `fill`],
  [`min`, `inside`],
])

const generateImageSource = (
  filename,
  width,
  height,
  toFormat,
  fit,
  options,
) => {
  const { builder } = options
  const src = builder.width(width).height(height).auto('format').url()
  // console.log('generateImageSource: ', src, width, height)
  return { width, height, format: 'auto', src}
}

// type ImageFit = 'clip' | 'crop' | 'fill' | 'fillmax' | 'max' | 'scale' | 'min'
/*
export type GatsbyImageDataArgs = {
  width?: number
  height?: number
  aspectRatio?: number
  layout?: Layout
  sizes?: string
  placeholder?: 'blurred' | 'dominantColor' | 'none'
  fit?: ImageFit
}
*/

// gatsby-plugin-image
export function getGatsbyImageData(
  image,
  { fit, ...args},
  loc = clientConfig.sanity,
) {
  const imageStub = getBasicImageProps(image, loc, args)

  if (!imageStub || !image) {
    return null
  }

  // const {width, height, aspectRatio} = imageStub.metadata.dimensions
  const {width: intrinsicWidth, height: intrinsicHeight, aspectRatio} = imageStub.metadata.dimensions

  const builder = imageUrlBuilder(loc).image(image)
  // console.log('imageUrlBuilder: ', builder, image)

  // @todo: check if the builder is creating the bad rect param

  // tile === true
  // if (tile === true) {}

  const portrait = aspectRatio <= 1
  let width = intrinsicWidth
  let height = intrinsicHeight
  if (args.tile) {
    // 16:9 (0,5625)
    // 1260 - 800 = 460
    width = 1260 // 1260
    height = 709 // 709
    if (portrait) {
      // 8:9 (0,8888)
      width = 1260
      height = 1418
    }
  }

  const imageProps = generateImageData({
    ...args,
    pluginName: `gatsby-source-sanity`,
    sourceMetadata: {
      format: 'auto',
      width,
      height,
    },
    fit: fit ? fitMap.get(fit) : undefined,
    filename: imageStub.url,
    generateImageSource,
    options: {builder},
    formats: ['auto'],
    breakpoints: EVERY_BREAKPOINT,
  })


  let placeholderDataURI

  if (args.placeholder === `dominantColor`) {
    imageProps.backgroundColor = get(imageStub, 'metadata.palette.dominant.background')
  }

  if (args.placeholder === `blurred`) {
    imageProps.placeholder = imageStub.metadata.lqip
      ? {fallback: imageStub.metadata.lqip}
      : undefined
  }

  if (placeholderDataURI) {
    imageProps.placeholder = {fallback: placeholderDataURI}
  }

  return imageProps
}