import React, { useState, useEffect } from 'react'
import { Box } from 'theme-ui'
import { useMediaQuery } from 'react-responsive'

function viewportToColumns(index) {
  switch (true) {
    case index === 2:
      return 12
    case index === 1:
      return 6
    default:
      return 4
  }
}

const Grid = () => {

  const [showGrid, setShowGrid] = useState(false)

  const handleKeydown = (e) => {
    if (e.key === 'g' && e.ctrlKey) {
      setShowGrid(prevState => !prevState)
    }
  }

  useEffect(() => {
    console.log('register keypress listener')
    window.addEventListener('keydown', handleKeydown, { passive: true })
    return () => {
      window.removeEventListener('keydown', handleKeydown)
    }
  }, [])

  const viewport = [
    useMediaQuery({ query: '(min-width: 0px)' }),
    useMediaQuery({ query: '(min-width: 768px)' }),
    useMediaQuery({ query: '(min-width: 1280px)' }),
  ]
  const columns = viewportToColumns(viewport.lastIndexOf(true))
  return (
    <Box
      className='css-grid'
      sx={{
        visibility: showGrid ? 'visible' : 'hidden',
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        px: 2,
        zIndex: 999,
        zIndex: 9999,
        pointerEvents: 'none'
      }}
   >
     {[...Array(columns)].map((_,i) => <Box key={`col-${i}`} sx={{ bg: 'rgba(0 0 0 / 0.08)'}}/>)}
   </Box>
  )
}

export default Grid
