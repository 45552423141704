import algoliasearch from 'algoliasearch/lite'
import Footer from 'components/Footer'
import Menu from 'components/Menu'
import Transition from 'components/Transition'
import 'css/fonts.css'
import 'css/layout.css'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import qs from 'qs'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import { Box, Container } from 'theme-ui'
import Grid from './Grid'

import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { getResolvedLocale, isDefaultLng } from 'lib/i18n'
import locales from 'locales.js'
import { Helmet } from 'react-helmet'
import { I18nextProvider, initReactI18next } from 'react-i18next'

export const AlternateLinksContext = React.createContext([])
export const CommonTranslationsContext = React.createContext([])

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    initImmediate: false,
    whitelist: locales.map(locale => locale.code),
    nonExplicitWhitelist: true,
    load: 'languageOnly',
    resources: locales.reduce(
      (acc, locale) => ({
        ...acc,
        [locale.code]: locale,
      }),
      {}
    ),
    debug: false,
    verbose: false,
    detection: {
      // this detection isn't working very well
      order: ['path', 'localStorage', 'navigator'],
    },
    fallbackLng: 'en_GB',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

let isRedirecting = false
const resolvedLocale = getResolvedLocale(i18next)

if (
  typeof window !== "undefined" &&
  window.location.pathname === "/" &&
  !isDefaultLng(resolvedLocale.ogLang)
) {
  isRedirecting = true
  window.location = `/${resolvedLocale.ogLang}`
}

// mapping to display simple 'categories' in the url bar
// @TODO: update this according to categories.title.localized
const attributes = {
  'categories.title.en_GB': 'categories',
  'categories.title.gr': 'katigories',
  'categories.title.de': 'kategorien'
}

const DEBOUNCE_TIME = 700

const createURL = (state) => {
  // let isDefaultRoute =
  //     !state.query &&
  //     state.page === 1
  //
  // if (isDefaultRoute) {
  //     return ''
  // }

  return qs.stringify(state, {
    addQueryPrefix: true,
    arrayFormat: 'repeat'
  })
}

const searchStateToUrl = (location, searchState) => {
  const {
    // query,
    menu
  } = searchState

  const queryObject = {}
  // remove query from URL
  // if (query) {
  //   queryObject.query = query
  // }
  for (const [algoliaAttributeCode, queryStringCode] of Object.entries(attributes)) {
    if (!menu) {
      continue
    }
    const currentStateValue = menu[algoliaAttributeCode]
    if (!currentStateValue) {
      continue
    }
    queryObject[queryStringCode] = currentStateValue
    console.log(`set url ${queryStringCode}`, currentStateValue)
  }

  return queryObject ? `${location.pathname}${createURL(queryObject)}` : ''
}

// this is parsing url to algolia state structure
const urlToSearchState = location => {
  // location.search.slice(1) -> this removes ? from the query string
  const {
    query,
    page,
    ...other
  } = qs.parse(location.search.slice(1))
  const queryObject = {
    menu: {}
  }
  if (query) {
    queryObject.query = query
  }
  if (other) {
    for (const [key, value] of Object.entries(attributes)) {
      if (other && other[value]) {
        queryObject.menu[key] = other[value]
        console.log(`set menu ${key}`, other[value])
      }
    }
  }

  return qs.parse(queryObject)
}

export const fragment = graphql`
  fragment LocalizedSettings on SanitySiteSettings {
    title
    description {
      localized
    }
    translations {
      search {
        localized
      }
      initiatives {
        localized
      }
      about {
        localized
      }
      categories {
        localized
      }
      relatedInitiatives {
        localized
      }
      readOn {
        localized
      }
      readLess {
        localized
      }
    }
  }
`

const Layout = (props) => {

  const { data, errors, children, location, path, pageContext } = props

  const { sanitySiteSettings } = useStaticQuery(
    graphql`
      query SanitySiteSettings {
        sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
          title
          aboutPage {
            i18n_path
            i18n_refs {
              ref {
                ... on SanityPage {
                  id
                  i18n_lang
                  i18n_path
                }
              }
            }
          }
        }
      }
    `
  )

  const pageContextLanguage = pageContext.language || 'en_GB'

  // Load commons data in i18next
  i18next.addResourceBundle(pageContextLanguage, 'translations', {
    commons: {},
    settings: sanitySiteSettings,
  })

  // eslint-disable-next-line
  const [searchState, setSearchState] = useState(
    urlToSearchState(location)
  )
  const setStateId = useRef()

  // detect home route and only show animation in this case
  const isHome = (path === '/*' || path === '/' || path === '/de/' || path === '/el/')
  // @BACKLOG: handle this if we translate `initiative`
  const isInitiative = path.includes('/initiative/')
  const [showIntro, setShowIntro] = useState(true)

  const [showMenu, setShowMenu] = useState(false)
  const showMenuHandler = (bool) => {
    setShowMenu(bool)
  }

  useEffect(() => {
    if (isHome) {
      setTimeout(() => {
        setShowIntro(false)
        setShowMenu(true)
      }, 3000)
    } else {
      if (!isInitiative) {
        setShowMenu(true)
      }
      setShowIntro(false)
    }
  }, [])

  useEffect(() => {
    document.documentElement.classList.toggle('scroll-lock', showIntro)
    return () => document.documentElement.classList.remove('scroll-lock')
  }, [showIntro])

  useEffect(() => {
    const nextSearchState = urlToSearchState(location)

    if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
      setSearchState(nextSearchState)
    }
    // eslint-disable-next-line
  }, [location])

  function onSearchStateChange(nextSearchState) {
    if (nextSearchState // 👈 null and undefined check
      && Object.keys(nextSearchState).length === 0
      && Object.getPrototypeOf(nextSearchState) === Object.prototype) {
      return false
    }

    clearTimeout(setStateId.current)
    let url = location.pathname
    if (location.search) {
      url += location.search
    }
    const newUrl = searchStateToUrl(location, nextSearchState)
    console.log('onSearchStateChange: ', searchState, nextSearchState)
    setStateId.current = setTimeout(() => {
      // maybe need to compare query args too...needs testing
      if (url !== newUrl) {
        console.log('navigate to nextSearchState: ', location, url, newUrl)
        navigate(newUrl)
      }
    }, DEBOUNCE_TIME)

    setSearchState(nextSearchState)
  }

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

  const refineRef = React.useRef(null)

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, {
      searchState,
      showIntro,
      refineRef,
      showMenuHandler
    }),
  )

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
    <I18nextProvider i18n={i18next}>
      <CommonTranslationsContext.Provider value={data.site?.translations}>
        <AlternateLinksContext.Provider
          value={props.pageContext}
        >
          {
            <Helmet htmlAttributes={{ lang: pageContextLanguage }}>
              {props.pageContext &&
                props.pageContext.alternateLinks &&
                props.pageContext.alternateLinks.map(link => (
                  <link
                    key={`alternate-${link.lang}`}
                    rel='alternate'
                    hrefLang={link.lang}
                    href={link.path}
                  />
                ))}
            </Helmet>
          }
          <InstantSearch
            searchClient={searchClient}
            indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
            // createURL={createURL}
          >
            <Menu
              facetOrdering={true}
              path={path}
              location={location}
              attribute={'categories.title.localized'}
              showIntro={showIntro}
              showMenu={showMenu}
              refineRef={refineRef}
              site={data?.site || {}}
              aboutPage={sanitySiteSettings.aboutPage || {}}
            />
            <Configure
              filters={`i18n_lang:${pageContextLanguage}`}
              hitsPerPage={33}
              analytics={false}
              enablePersonalization={false}
            />
              { /* isRedirecting ? null : <Transition location={location}>{childrenWithProps}</Transition> */}
              <Transition location={location}>{childrenWithProps}</Transition>
              <Box sx={{ flexGrow: 1 }}/>
              <Footer site={data?.site || {}} path={path} location={location} />
            </InstantSearch>
          </AlternateLinksContext.Provider>
          </CommonTranslationsContext.Provider>
      </I18nextProvider>
      <Grid />
    </Box>
  )
}

export default Layout
