/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import get from 'lodash.get'
import BlockContent from '@sanity/block-content-to-react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'lib/getGatsbyImageProps'
import { Box } from 'theme-ui'
import Corner from 'components/Corner'
import { sizeToCol } from 'lib/helpers'

const Caption = ({children, inverted}) =>
  <figcaption sx={{
    transition: 'opacity .5s, transform .25s',
    transform: 'translate(0, 0)',
    opacity: 1,
    position: 'absolute',
    zIndex: '200',
    bottom: '0',
    left: 0,
    // ...(sizeToWidth(size)),
    // 'width': '83%',
    p: '3pt 5pt 0',
    borderTopRightRadius: 'default',
    borderBottom: 'none',
    color: inverted ? 'secondary' : 'black',
    bg: inverted ? 'black' : 'white'
  }}>
    <Box sx={{position: 'absolute', bottom: `100%`, left: `0`}}><Corner left inverse={inverted}/></Box>
    <Box sx={{position: 'absolute', bottom: `0`, left: `100%`}}><Corner left inverse={inverted}/></Box>
    {children}
  </figcaption>

const Figure = ({ node, inverted }) => {
  // console.log('Figure: ', node)
  return (
    <figure
      sx={{
        position: 'relative',
        overflow: 'hidden',
        gridColumn: 'span 6',
        alignSelf: node.alignSelf,
        ...sizeToCol(node.size || 'large'),
        // node.size
        // marginBottom: `4rem`,
        // marginRight: 'auto',
      }}
    >
      <GatsbyImage image={getGatsbyImageData(node, { hotspot: node.hotspot, tile: true, placeholder: 'dominantColor' })} alt={node.alt || node.asset.altText || ''} />
      {
        node.blockCaption ? (
          <Caption inverted={inverted}><BlockContent blocks={node.blockCaption} /></Caption>
        ) : (
          node.caption ? <Caption inverted={inverted}><span dangerouslySetInnerHTML={{ __html: node.caption }}/></Caption> : <div/>
        )
      }
    </figure>
  )
}

export default Figure
