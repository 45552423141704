/** @jsx jsx */
import { jsx, Text, Heading, Link } from 'theme-ui'
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import ImagesModule from 'components/Modules/ImagesModule'
import GalleryModule from 'components/Modules/GalleryModule'
import YoutubeModule from 'components/Modules/YoutubeModule'
import Footnotes from 'components/Modules/Footnotes'

const BlockRenderer = (props) => {
  const { style = 'normal' } = props.node

  if (style === 'title') {
    return <Heading as='h4' variant='fms'>{props.children}</Heading>
  }

  if (style === 'large') {
    return <Text className='large-text' as='div' variant='frxl' sx={{ mb: 4 }}>{props.children}</Text>
  }

  if (style === 'blockquote') {
    return <blockquote>- {props.children}</blockquote>
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}

const serializers = (blocks, notes, count = 0, inverted) => {
  return {
    types: {
      footnotes: (props) => <Footnotes {...props} richText={blocks} notes={notes} count={count} />,
      block: BlockRenderer,
      imagesModule: (props) => <ImagesModule {...props} />,
      galleryModule: (props) => {
        return props.node.images && props.node.images.length > 2 ? <GalleryModule {...props} /> : <ImagesModule {...props} />
      },
      youtubeModule: (props) => <YoutubeModule {...props} />,
      readMore: () => <div/> // the serializer complains without this
      // @TODO: remove like we do the sectionLink
    },
    marks: {
      internalLink: ({ mark, children }) => {
        const path = mark?.reference?._type === 'page' ? '' : 'initiative/'
        const href = `${path}/${mark?.reference?.slug?.current}`
        // <GatsbyLink> or <PopOut> (?)
        return <GatsbyLink to={href}>{children}</GatsbyLink>
      },
      link: ({ mark, children }) => {
        const { href } = mark
        return <Link variant={inverted ? 'inverted' : 'text'} href={href} target="_blank" rel="noopener">{children}</Link>
      },
      footnote: ({ children, markKey, mark }) => {

        const handleClick = (event) => {
          event.preventDefault();
          const anchorTarget = document.getElementById(event.target.hash.replace("#", ""))
          if (anchorTarget) anchorTarget.scrollIntoView({ behavior: "smooth", block: "start" });
        }

        const number = (notes || []).findIndex(note => note._key === markKey) + 1 + count
        return (
          <Link
            href={`#${markKey}`}
            onClick={handleClick}
            variant='number'
          >
            {number}
          </Link>
        )
      }
    }
  }
}

export default serializers
