import React from 'react'
import Youtube from 'react-youtube'
import getYouTubeId from 'get-youtube-id'
import { Box } from 'theme-ui'
import BlockContent from '@sanity/block-content-to-react'
import useResponsiveConfig from 'lib/useResponsiveConfig'

const responsiveConfig = [
  {
    // 320
    gridColumn: '1/5'
  },
  null,
  {
    gridColumn: '1/5'
  },
  {
    // 1280
    gridColumn: '3/10'
  }
]

const YoutubeModule = ({ node }) => {
  const { url, blockCaption } = node
  const id = getYouTubeId(url)
  const currentConfig = useResponsiveConfig(responsiveConfig)
  return (
    <Box sx={{ ...currentConfig, mb: 7 }}>
      <Box
        sx={{
          borderRadius: 'default',
          overflow: 'hidden',
          position: 'relative',
          paddingBottom: '56.25%',
          height: 0,
          iframe: {
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          },
        }}
      >
        <Youtube videoId={id} />
      </Box>
      {
        blockCaption && (
          <Box sx={{ variant: 'text.fms', mt: 1, 'span, div, p': { display: 'inline' } }}><span>↑ </span><BlockContent renderContainerOnSingleChild blocks={blockCaption} /></Box>
        )
      }
    </Box>

  );
};

export default YoutubeModule
