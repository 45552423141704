/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useContext } from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { CommonTranslationsContext } from 'layouts'
import { Text, Box } from 'theme-ui'
import TextTruncate from 'react-text-truncate'
import useCollapse from 'react-collapsed'

const Footnote = ({id, text, number}) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    collapseStyles: { opacity: 0 },
    expandStyles: { opacity: 1 }
  })
  const { readOn, readLess } = useContext(CommonTranslationsContext)
  const textForTruncation = text[0].children.map(child => child.text).join('')
  return (
    <div
      id={`${id}`}
      sx={{
        position: 'relative',
        pl: 5,
        mb: 2,
        minHeight: '20px',
      }}
    >
      <Text
        variant='frs'
        sx={{
          position: 'absolute',
          top: '-4px',
          left: 0,
          bg: isExpanded ? 'transparent' : 'black',
          color: 'white',
          borderRadius: '50%',
          border: '1px solid',
          borderColor: 'transparent',
          fontSize: 0,
          width: '20px',
          height: '20px',
          lineHeight: '21px',
          display: 'inline-block',
          textAlign: 'center',
          zIndex: isExpanded && 301
        }}
      >
        {number}
      </Text>
      <Text variant='fms'>
        <TextTruncate
          line={3}
          element='p'
          truncateText='…'
          text={textForTruncation}
          textTruncateChild={<span {...getToggleProps()} sx={{ variant: 'links.popOver' }}>{readOn?.localized}</span>}
        />
        <Box
          {...getCollapseProps()}
          sx={{
            transition: 'opacity .25s cubic-bezier(0.4, 0, 0.2, 1)',
            position: 'absolute',
            top: '-5px',
            left: 0,
            pl: 3,
            bg: 'black',
            color: 'white',
            zIndex: 300,
            borderRadius: 'default',
            'p > a': {
              color: 'white',
              ['::after']: { content: `'[↗]'`, mr: 1 }
            }
          }}
        >
          <Box sx={{p: 1}}>
            <BlockContent blocks={text}/>
            <span {...getToggleProps()} sx={{ variant: 'links.inverted' }}>{readLess?.localized}</span>
          </Box>
        </Box>
      </Text>
    </div>
  )
}

const Footnotes = ({ node = {}, richText, notes, count = 0}) =>
  <Box sx={{
    gridColumn: ['1/5', null, '5/7', '9/11', '8/10'],
    position: 'relative'
  }}>
    <Box sx={{
      position: ['relative', null, 'absolute'],
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column'
    }}>
      {(node.children || []).map(({ _key, text }) => {
        const number = notes.findIndex(note => note._key === _key) + 1 + count
        return <Footnote id={_key} key={_key} text={text} number={number}/>
      })
    }
    </Box>
  </Box>

export default Footnotes
