/** @jsx jsx */
import BlockContent from '@sanity/block-content-to-react'
import Carousel from 'components/Carousel'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'lib/getGatsbyImageProps'
import useResponsiveConfig from 'lib/useResponsiveConfig'
import get from 'lodash.get'
import React from 'react'
import { jsx } from 'theme-ui'

const responsiveConfig = [
  {
    // less 320px
    height: 146,
    clones: 1,
  },
  null,
  {
    // 768px
    height: 299,
    clones: 2,
  },
  {
    // 1280px
    height: 382,
    clones: 3,
  },
  {
    // 1680px
    height: 456,
    clones: 4,
  },
  {
    // 2560px
    height: 693,
    clones: 5,
  },
]

const GalleryModule = ({ node = {} }) => {
  const currentConfig = useResponsiveConfig(responsiveConfig)
  const slides = (node.images || []).map(image => {
    const { aspectRatio } = get(image, 'asset.metadata.dimensions', {})
    // target height: 473px (this needs to be recalculated based on window size)
    const portrait = aspectRatio < 1
    const height = currentConfig ? currentConfig.height : 473
    const width = height * aspectRatio
    const render = (
      <figure key={image._key} sx={{ pl: 2 }} style={{ width: `${width + 10}px` }}>
        <GatsbyImage
          image={getGatsbyImageData(image, { width: width * 2, placeholder: 'dominantColor' })}
          alt={image.alt || image.asset?.altText}
        />
        {image.blockCaption ? (
          <figcaption
            sx={{
              'span, div, p': { display: 'inline' },
            }}
          >
            <span>↑ </span>
            <BlockContent renderContainerOnSingleChild blocks={image.blockCaption} />
          </figcaption>
        ) : (
          <figcaption />
        )}
      </figure>
    )

    return {
      width: width + 10,
      render,
      key: node._key,
    }
  })

  if (slides.length > 0) {
    return (
      <div
        sx={{
          gridColumn: ['1/5', null, '1/7', '1/13'],
          mx: '-10px',
          overflow: 'hidden',
          my: 7,
          position: 'relative',
          ':hover': {
            '.arrow': {
              opacity: 1,
              transform: 'translateX(0)',
            },
          },
        }}
      >
        <Carousel slides={slides} clones={currentConfig && currentConfig.clones} />
      </div>
    )
  }
  return <div />
}

export default GalleryModule
