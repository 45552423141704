import React, { useContext } from 'react'
import locales from 'locales.js'

export const getResolvedLocale = i18n => {
  let currentLng

  for (let language of i18n.languages) {
    currentLng = locales.find(locale => locale.ogLang === language)
    if (currentLng) {
      break
    }
  }

  return currentLng
}

export const isDefaultLng = lng => {
  return !!locales.find(locale => locale.ogLang === lng && locale.isDefault)
}

// this is not used in the example code base
export const localizeUrl = (t, { url } = {}) => {
  const parsedUrl = url === "/" ? "" : url
  return isDefaultLng(t("code", { ns: "config" }))
    ? `${parsedUrl}/`
    : `/${t("path", { ns: "config" })}${parsedUrl}/`
}

// this is not used in the example code base
export const getLocaleForCode = code =>
  locales.find(locale => locale.ogLang.substr(0, 2) === code)

// this is not used in the example code base
export const LocalesContext = React.createContext()
export const useI18n = () => useContext(LocalesContext)
