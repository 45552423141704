import { CornerV2 as Corner } from 'components/Corner'
import ListModule from 'components/Modules/ListModule'
import Page from 'components/Page'
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby'
import logo from 'img/schwarz-foundation-logo.svg'
import { AlternateLinksContext } from 'layouts'
import getResponsiveStyles from 'lib/getResponsiveStyles'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import useCollapse from 'react-collapsed'
import { useTranslation } from 'react-i18next'
import { Box, Button, Container, Flex, Grid, Link, Text } from 'theme-ui'

export const useSiteSettings = () => {
  // @TODO: query for the about page from sanitySiteSettings
  const data = useStaticQuery(
    graphql`
      query SiteSettings {
        site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
          aboutPage {
            _rawModules(resolveReferences: {maxDepth: 10})
            i18n_refs {
              ref {
                ... on SanityPage {
                  _rawModules(resolveReferences: {maxDepth: 10})
                  i18n_lang
                }
              }
            }
          }
          subMenu {
            title
            i18n_path
            i18n_refs {
              ref {
                ... on SanityPage {
                  i18n_path
                  i18n_lang
                  title
                }
              }
            }
          }
          socialAccounts {
            href
            title
            _type
            _key
          }
          relatedFoundations {
            href
            title
            _type
            _key
          }
          legalMenu {
            title
            i18n_path
            i18n_refs {
              ref {
                ... on SanityPage {
                  i18n_path
                  i18n_lang
                  title
                }
              }
            }
          }
        }
      }
    `
  )
  return data
}

const Logo = ({ homeLink }) =>
  <Box
    sx={{
      // width: 'calc(100% + 10px)',
      width: '100%',
      height: '45px',
      bg: 'white',
      borderBottomRightRadius: 'large',
      borderBottomColor: 'black',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 200,
      'img': { mt: 1, ml: 2, maxWidth: '130px' }
    }}
  >
  <Link as={GatsbyLink} to={homeLink} sx={{
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    cursor: 'pointer'
  }}>
  <img width='100%' height='auto' src={logo} />
  </Link>
  {/*<Corner bottom='0' right='-10px' inverse large />*/}
  <Corner top='100%' left='0' />
</Box>

const EASE = `cubic-bezier(0.59, 0.01, 0.28, 1)`


const Footer = (props) => {
  const [isExpanded, setExpanded] = useState(false)
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
    easing: EASE,
    duration: 1600
  })

  // @TODO:
  // onCollapseEnd handler to set a state to unmount <Page /> component
  // onExpandStart, can that be called to mount the <Page /> component?
  // if not, we have the about count on every page, but maybe it is hidden from SEO?
  // @TODO: smooth about animation
  // let's delay the expand with a settimeout, maybe that already helps
  useEffect(() => {
    if (props.location.pathname.includes('/about')) {
      setTimeout(() => setExpanded(true), 600)
    } else {
      setExpanded(false)
    }
  }, [props.location.pathname])

  const { title, description } = props.site

  const { page, site } = useSiteSettings()
  const {
    aboutPage,
    subMenu,
    socialAccounts,
    relatedFoundations,
    legalMenu
  } = site

  const { alternateLinks, language, isDefault } = useContext(AlternateLinksContext)
  const { t, i18n } = useTranslation('common')
  // console.log('i18n: ', i18n)
  // use the path to detect what language we currently are on
  // en_GB has no path
  const homeLink = language === 'en_GB' || language === undefined  ? '/' : `/${language}`

  const i18nSubMenu = useCallback(() => {
    return subMenu.map(page => {
      if (!page?.i18n_refs || isDefault) return page
      const ref = page.i18n_refs.find(({ref}) => ref.i18n_lang === language)?.ref || false
      // we might not have a translation of the about page
      if (!ref) return page
      return {
        title: ref.title,
        i18n_path: ref.i18n_path
      }
    })
  }, [props.location.pathname])

  const i18nLegalMenu = useCallback(() => {
    return legalMenu.map(page => {
      if (!page?.i18n_refs || isDefault) return page
      const { title, i18n_path } = page.i18n_refs.find(({ref}) => ref.i18n_lang === language)?.ref || {}
      return {
        title,
        i18n_path
      }
    })
  }, [props.location.pathname])

  // get current language about page from refs of base language about page
  let i18nAboutPage = aboutPage
  if (!isDefault) {
    i18nAboutPage = aboutPage.i18n_refs?.find(({ref}) => ref.i18n_lang === language)?.ref || false
    // we might not have a translation of the about page
    if (!i18nAboutPage) i18nAboutPage = aboutPage
  }

  return (
    <Box sx={{
      pt: '150px',
      bg: 'white'
    }}>
      <Flex direction='row'>
          <Box sx={{
            position: 'relative',
            height: '35px',
            // gridColumn: ['span 2', null, null, '1/3', null, '1/2'],
            width: ['75%', null, '33%', '16.66%', null, '8.33%']
          }}
          >
            <Logo homeLink={homeLink} />
          </Box>
          <Box sx={{
            // zIndex: -1,
            bg: 'black',
            position: 'relative',
            height: '35px',
            borderTopLeftRadius: 'default',
            borderTopRightRadius: 'default',
            // gridColumn: ['span 2', null, 'span 4', 'span 10', null, 'span 11'],
            // ml: ['50%', null, null, 'calc(16.67% - 10px)', null, '8.34%'],
            width: ['25%', null, '67%', '83.33%', null, '91.66%']
          }}
          >
          </Box>
      </Flex>
      <Box
        sx={{
          color: 'invertedText',
          position: 'relative',
          bg: 'black',
          // minHeight: ['auto', null, '25vh'],
          // mt: [0, null, '-25px'],
          pt: [4, null, 0],
          pb: 2,
          px: 2
        }}
      >
        <Box
          className='css-col-grid'
          sx={{
            //position: ['relative', null, 'absolute'],
            position: 'relative',
            top: ['unset', null, '-25px'],
            bg: ['black', null, 'transparent']
          }}
          >
          <Box
            mb='4'
            sx={{ gridColumn: ['span 4', null, '3/7', null, null, '2/6'] }}
          >
            <Box
              sx={{
                ...(getResponsiveStyles('variant', ['text.frl', null, null, null, null, 'text.frxl'])),
                mt: 0
              }}>
              {description?.localized}
              <Box as='span' sx={{ px: 1 }}></Box>
              {(i18nSubMenu() || []).map(({ title, i18n_path }) => <Link key={i18n_path} as={GatsbyLink} to={i18n_path} variant='buttonInverted' sx={{ mr: 1, display: 'inline-flex' }}>{title}</Link>)}
            </Box>
          </Box>
          <Box sx={{ gridColumn: ['span 4', null, '3/4', '8/10'] }}>
            <ListModule node={{ items: socialAccounts}} inverted />
          </Box>
          <Box sx={{ gridColumn: ['span 4', null, '5/7', '10/12'] }}>
            {relatedFoundations?.length > 0 && <ListModule title={'Related foundations'} node={{ items: relatedFoundations }} inverted /> }
          </Box>
        </Box>
        <Box className='css-col-grid'>
          <Box sx={{ display: ['none', 'block'], gridColumn: ['span 4', 'span 1', '3/5', '8/9'], alignSelf: 'flex-end' }}>
            <Box sx={{ variant: 'text.fms', display: 'inline-flex', flexDirection: 'row', ml: 0 }}>
              {(alternateLinks || [])
                .map((link, i) => [
                  i > 0 && <span>{`/`}</span>,
                  <Link
                    key={`lang-${i}`}
                    as={GatsbyLink}
                    variant='inverted'
                    to={link.path}
                    sx={{ color: link.current && 'white !important' }}
                    hrefLang={link.language}
                  >
                    {link.label}
                  </Link>,
              ])}
            </Box>
          </Box>
          <Box sx={{ gridColumn: ['span 4', null, '5/7', '10/13'], alignSelf: 'flex-end' }}>
            <Box sx={{ variant: 'text.fms', ml: 0, display: 'inline-flex', flexDirection: ['column', null, 'row'] }}>
              {(i18nLegalMenu() || []).map(({ title, i18n_path }) => <div key={i18n_path}><Link as={GatsbyLink} to={i18n_path} variant='inverted' sx={{ mr: 3 }}>{title}</Link></div>)}
            </Box>
          </Box>
        </Box>
        <Box {...getCollapseProps()}>
          <Page {...i18nAboutPage} inverted={true} pt={2} px={'0px'} />
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
