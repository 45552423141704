module.exports = {
  sanity: {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
    dataset: process.env.GATSBY_SANITY_DATASET,
    // useCdn: true
    // `false` if you want to ensure fresh data
    // https://www.npmjs.com/package/@sanity/client
    // Setting a value for useCdn is encouraged
    // gatsby-source-sanity doesn't mention this option
  }
}
