import { format, isFuture } from "date-fns";

export function mapEdgesToNodes(data) {
  if (!data.edges) return [];
  return data.edges.map(edge => edge.node);
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current;
}

export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(publishedAt);
}

export function buildImageObj(source) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id }
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

export function sizeToCol(size) {
  switch (true) {
    case size === 'large' || size === 'injected':
      return { 'gridColumn': ['span 4', null, 'span 5', 'span 6', 'span 5'] }
    case size === 'category':
      return { 'gridColumn': ['span 4', null, 'span 3', 'span 6', 'span 4'] }
    case size === 'medium':
      return { 'gridColumn': ['span 4', null,  'span 3', 'span 4', 'span 3'] } // 4
    default:
      return { 'gridColumn': ['span 3', 'span 2'] } // 2
  }
}
