// https://github.com/browser-update/browser-update
import browserUpdate from 'browser-update'
import "./src/css/browser-update.css"

// the script will detect if the spec is natively supported and take action only when necessary
import smoothscroll from 'smoothscroll-polyfill'

export const onClientEntry = async () => {

  // Chrome 49 (release: 2016/3/2)
  // Firefox 50 (release: 2016/11/15)
  // Safari 10 (release: 2016/9/20)
  // Edge 14 (release: 2016/2/18)
  // IE 10 (release: 2012/10/26)

  browserUpdate({
    required: {f:50,o:50,s:10,c:49, e:14},
    // Specifies required browser versions
    // Browsers older than this will be notified.
    // f:22 ---> Firefox < 22 gets notified
    // Negative numbers specify how much versions behind current version.
    // c:-5 ---> Chrome < 35  gets notified if latest Chrome version is 40.
    l: false, // always english
    test: false,
    text: {
      'msg':'Your web browser ({brow_name}) is out of date.',
      'msgmore': 'This website relies on modern browser technology. Update your browser for more security, speed and the best experience on this site.',
      'bupdate': 'Update browser',
      'bignore': 'Ignore',
      'remind': 'You will be reminded in {days} days.',
      'bnever': 'Never show again'
    }
    // custom notification text (html)
    // Placeholders {brow_name} will be replaced with the browser name, {up_but} with contents of the update link tag and {ignore_but} with contents for the ignore link.
    // Example: "Your browser, {brow_name}, is too old: <a{up_but}>update</a> or <a{ignore_but}>ignore</a>."
    // more details (in english)

  })

  smoothscroll.polyfill()

  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
    console.log(`👍 IntersectionObserver is polyfilled`)
  }

}
