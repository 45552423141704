import React from 'react'
import BaseBlockContent from '@sanity/block-content-to-react'
import Figure from 'components/Modules/Figure'
import PageRichTextModule from 'components/Modules/PageRichTextModule'
import clientConfig from '../../client-config'

const PageBlockContent = ({ blocks, inverted }) => (
  <BaseBlockContent
    className={'css-grid grid-end'}
    blocks={blocks}
    serializers={{
      types: {
        figure: (props) => <Figure {...props} inverted={inverted} />,
        blockTextModule: (props) => <PageRichTextModule {...props} inverted={inverted} />,
      }
    }}
    {...clientConfig.sanity}
  />
);

export default PageBlockContent
